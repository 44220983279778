import React from 'react';
import './PrimaryButton2.scss';
import primaryButtonImage from '../../static/img/base-1@1x.png';

class PrimaryButton2 extends React.Component {
  render() {
    const {  openPositions, className } = this.props;

    return (
      <div
        className={`button-1 ${className || ''}`}
        style={{ backgroundImage: `url(${primaryButtonImage})` }}
      >
        <div className='the-robots atkinsonhyperlegible-bold-white-18px'>{openPositions}</div>
      </div>
    );
  }
}

export default PrimaryButton2;

import React from 'react';
import './AtAGlance.scss';
import autonomousprograminImage from '../../static/img/icon-autonomous-programming@1x.png';
import iconscanplanImage from '../../static/img/icon-scan---plan-1@1x.png';
import iconflexiblefixturingImage from '../../static/img/icon-flexible-fixturing-1@1x.png';
import icontoolwearImage from '../../static/img/icon-tool-wear-1@1x.png';
import iconautomaticcalibration from "../../static/img/icon-automatic-calibration-1@1x.png";


class AtAGlance extends React.Component {
  render() {
    const { className, hideAutonomousProgramming, hideFlexibleFixturing } = this.props;

    return (
      <div className={`at-a-glance ${className || ''}`}>
        <div className='at-a-glance-title atkinsonhyperlegible-bold-onyx-24px'>At A Glance</div>
        <div className='at-a-glance-icons'>
          {!hideAutonomousProgramming && (
            <div className='no-code-programming'>
              <img
                className='icon-autonomous-programming'
                src={autonomousprograminImage}
                alt={autonomousprograminImage}
              />
              <div className='autonomous-programming atkinsonhyperlegible-bold-onyx-14px'>
                Autonomous <br />
                Programming
              </div>
            </div>
          )}
          <div className='scan-plan'>
            <img className='icon-scan-plan' src={iconscanplanImage} alt={iconscanplanImage} />
            <div className='scan-plan-name atkinsonhyperlegible-bold-onyx-14px'>Scan &amp; Plan</div>
          </div>
          {!hideFlexibleFixturing && (
            <div className='flexible-fixturing'>
              <img
                className='icon-flexible-fixturing'
                src={iconflexiblefixturingImage}
                alt={iconflexiblefixturingImage}
              />
              <div className='flexible-fixturing-name atkinsonhyperlegible-bold-onyx-14px'>
                Flexible Fixturing
              </div>
            </div>
          )}
          <div className='tool-wear-monitoring'>
            <img className='icon-tool-wear' src={icontoolwearImage} alt={icontoolwearImage} />
            <div className='tool-wear-monitoring-name atkinsonhyperlegible-bold-onyx-14px'>
              Tool Wear Monitoring
            </div>
          </div>
          <div className='automatic-system-calibration'>
            <img
              className='icon-automatic-calibration'
              src={iconautomaticcalibration}
              alt={iconautomaticcalibration}
            />
            <div className='automatic-system-calibration-name atkinsonhyperlegible-bold-onyx-14px'>
              Automatic System <br />
              Calibration
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AtAGlance;

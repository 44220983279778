import React from 'react';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop3 from '../HeroLargeDesktop3';
import ButtonHover from '../ButtonHover';
import Footer from '../Footer';
import './LrgRequestADemo.scss';

const siteKey = process.env.REACT_APP_SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default class LrgRequestADemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      submitSuccess: false,
      submitFailed: false,
      "g-recaptcha-response": '',
    };
    this.recaptchaRef = React.createRef();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = token => {
    this.setState({ "g-recaptcha-response": token });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const form = e.target;
    const token = await this.recaptchaRef.current.executeAsync();
    await new Promise(resolve => {
      this.setState({ "g-recaptcha-response": token }, resolve);
    });
    fetch('/special-form-handler', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': form.getAttribute('name'),
      ...this.state,
    }),
    })
    .then(response => {
      if (response.ok) {
        console.log('Form submitted successfully');
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          companyName: '',
          submitSuccess: true,
          submitFailed: false,
        });
        this.recaptchaRef.current.reset();
       } else {
      console.error(`HTTP error! status: ${response.status}`);
      this.setState({ submitSuccess: false, submitFailed: true });
      }
    })
    .catch(error => {
      console.error('Form submission error:', error);
      this.setState({ submitSuccess: false, submitFailed: true });
    });
  };

  render() {
    const {
      firstName,
      inputType1,
      inputPlaceholder1,
      lastName,
      inputType2,
      inputPlaceholder2,
      headerLargeDesktopProps,
      heroLargeDesktop3Props,
      email21Props,
      email22Props,
      buttonHoverProps,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <div className='lrg-request-a-demo screen'>
          <Helmet>
            <meta
              name='robots'
              content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
            />
            <title>Request A Demo | Rivelin</title>
            <meta name='description' content='Metal finishing made simple' />
            <meta name='keywords' content='Rivelin demo' />
            <meta property='og:description' content='Metal finishing made simple' />
            <meta property='og:site_name' content='Rivelin Robotics' />
            <meta property='og:title' content='Request A Demo' />
            <meta name='og:image' content='https://www.rivelinrobotics.com/img/mask-group-4@1x.png' />
            <meta
              name='og:image:secure_url'
              content='https://www.rivelinrobotics.com/img/mask-group-4@1x.png'
            />
            <meta
              name='image'
              property='og:image'
              content='https://www.rivelinrobotics.com/img/mask-group-4@1x.png'
            />
            <meta property='og:image:type' content='image/png' />
          </Helmet>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop3>{heroLargeDesktop3Props.children}</HeroLargeDesktop3>
          <form
            name="demoRequest"
            onSubmit={this.handleSubmit}
            data-netlify="true"
            data-netlify-recaptcha="true"
            >
            <div className='form-1'>
              <div className='first-name-2'>
                <div className='overlap-group-23'>
                  <div className='field-3 border-2px-gallery'></div>
                  <div className='st-name atkinsonhyperlegible-normal-bunker-26px'>{firstName}</div>
                  <input
                    className='x-5 atkinsonhyperlegible-normal-onyx-26px'
                    name='firstName'
                    placeholder={inputPlaceholder1}
                    type={inputType1}
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='last-name-2'>
                <div className='overlap-group-23'>
                  <div className='overlap-group-24'>
                    <div className='field-3 border-2px-gallery'></div>
                    <div className='st-name atkinsonhyperlegible-normal-bunker-26px'>{lastName}</div>
                  </div>
                  <input
                    className='x-6 atkinsonhyperlegible-normal-onyx-26px'
                    name='lastName'
                    placeholder={inputPlaceholder2}
                    type={inputType2}
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='form-1-item'>
                <div className='overlap-group-25'>
                  <div className='overlap-group-26'>
                    <div className='field-4 border-2px-gallery'></div>
                    <div className='email-1 atkinsonhyperlegible-normal-rose-26px'>
                      <span className='atkinsonhyperlegible-normal-bunker-26px'>
                        {email21Props.spanText1}
                      </span>
                      <span className='atkinsonhyperlegible-normal-rose-26px-2'>*</span>
                    </div>
                  </div>
                  <input
                    className='x-3 atkinsonhyperlegible-normal-onyx-26px'
                    name='email'
                    placeholder=' '
                    type='email'
                    required
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='form-1-item'>
                <div className='overlap-group-25'>
                  <div className='overlap-group-26'>
                    <div className='field-4 border-2px-gallery'></div>
                    <div className='email-1 atkinsonhyperlegible-normal-rose-26px'>
                      <span className='atkinsonhyperlegible-normal-bunker-26px'>
                        {email22Props.spanText1}
                      </span>
                      <span className='atkinsonhyperlegible-normal-rose-26px-2'>*</span>
                    </div>
                  </div>
                  <input
                    className='x-3 atkinsonhyperlegible-normal-onyx-26px'
                    name='companyName'
                    placeholder=' '
                    type='text'
                    required
                    value={this.state.companyName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              {!!this.state.submitSuccess && (
                <div className='submit-success'>
                  <span className='atkinsonhyperlegible-normal-bunker-24px'>Success! We'll be in touch.</span>
                </div>
              )}
              {!!this.state.submitFailed && (
                <div className='submit-failure'>
                  <span className='atkinsonhyperlegible-normal-bunker-24px'>
                    Woops! Something went wrong, try again or contact info@rivelinrobotics.com
                  </span>
                </div>
              )}
              <ReCAPTCHA
                ref={this.recaptchaRef}
                size='invisible'
                sitekey={siteKey}
                tabindex={3}
                onChange={this.handleRecaptcha}
              />
              <div className='submit-3'>
                <button type='submit' className='button-container-3'>
                  <ButtonHover />
                  <ButtonHover className={buttonHoverProps.className} />
                </button>
              </div>
            </div>
          </form>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import CopyrightNote from '../CopyrightNote';
import PrivacyPolicyLink from '../PrivacyPolicyLink';
import Twitter from '../Twitter';
import Instagram from '../Instagram';
import Youtube from '../Youtube';
import Linkedin from '../Linkedin';
import Github from '../Github';
import '../Footer/Footer.scss';
import footerLogoImage from '../../static/img/rr-logo-lgt-2@1x.png';
import rivelintortugImage from '../../static/img/rivelintortuga-1-1x-png@1x.png';
import line1Image from '../../static/img/line-1-1@1x.png';

class Footer extends React.Component {
  render() {
    const { privacyPolicyLinkProps, youtubeProps, linkedinProps, githubProps } = this.props;

    return (
      <div className='footer-shrinker'>
        <div className='tagline-banner'>
          <span className='span atkinsonhyperlegible-bold-bunker-20px'>
            2022 STARTUP OF THE YEAR @ FORMNEXT & 3D PRINTING INDUSTRY AWARDS
          </span>
        </div>
        <div className='footer'>
          <img className='rr_logo_lgt-1' src={footerLogoImage} alt='LogoNotFound' />

          <img className='rivelin-tortuga' src={rivelintortugImage} alt={rivelintortugImage} />
          <iframe
            title='cyber-essentials-plus'
            className='cyber-essentials-plus'
            src='https://registry.blockmarktech.com/certificates/5af86880-2b60-497d-b9cf-f5b5223a0b5d/widget/?non_padded=t&tooltip_position=corner&theme=transparent'
            style={{ border: 'none' }}
          ></iframe>
          <div className='address atkinsonhyperlegible-normal-white-26px'>
            <span className='span atkinsonhyperlegible-bold-white-26px'>Rivelin Robotics</span>
            <span className='span atkinsonhyperlegible-bold-white-22px'>
              <br />
            </span>
            <span className='span atkinsonhyperlegible-normal-white-22px'>
              RTC Unit 1, Neepsend Triangle<br />
              1 Burton Rd
              <br />
              Sheffield, UK
              <br />
              S3 8BW
            </span>
          </div>
          <div className='contact-us'>
            <HashLink to='/contact-us#top'>
              <div className='contact-us-1 atkinsonhyperlegible-bold-white-26px'>Contact Us</div>
            </HashLink>
            <HashLink to='/request-a-demo#top'>
              <div className='request-a-demo-13 atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-bold-white-26px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'>Request a Demo</span>
              </div>
            </HashLink>
            <a href='mailto:info@rivelinrobotics.com'>
              <div className='link-1 atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-bold-white-26px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'>info@rivelinrobotics.com</span>
              </div>
            </a>
            <a
              href='tel:+441147003984'
              target='_blank'
              rel='noopener noreferrer'
              title='Visit +44 114 700 3984 Channel'
            >
              <div className='link-1 atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-normal-white-22px'>+44 114 700 3984</span>
              </div>
            </a>
          </div>
          <div className='nav-footer'>
            <div className='rivelin-robotics atkinsonhyperlegible-bold-white-26px'>Rivelin Robotics</div>
            <HashLink to='/#top'>
              <div className='place atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-bold-white-26px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'>Home</span>
              </div>
            </HashLink>
            <HashLink to='/meet-the-robots#top'>
              <div className='link-2 atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-bold-white-26px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'>Meet The Robots</span>
              </div>
            </HashLink>
            <HashLink to='/how-we-work#top'>
              <div className='link-2 atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-bold-white-26px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'>How We Work</span>
              </div>
            </HashLink>
            <HashLink to='/who-we-are#top'>
              <div className='link-2 atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-bold-white-26px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'>Who We Are</span>
              </div>
            </HashLink>
            <HashLink to='/contact-us#top'>
              <div className='link-2 atkinsonhyperlegible-normal-white-26px'>
                <span className='span atkinsonhyperlegible-bold-white-26px'></span>
                <span className='span atkinsonhyperlegible-normal-white-22px'>Contact Us</span>
              </div>
            </HashLink>
          </div>
          <img className='line-1' src={line1Image} alt={line1Image} />
          <div className='footer-small-print'>
            <CopyrightNote />
            <div className='privacy-policy-link-container'>
              <PrivacyPolicyLink />
              <PrivacyPolicyLink className={privacyPolicyLinkProps.className} />
            </div>
          </div>
          <a
            href='https://github.com/rivelinrobotics'
            target='_blank'
            rel='noopener noreferrer'
            title='Visit +44 114 700 3984 Channel'
          >
            <div className='social-media-handles'>
              <Twitter />
              <Instagram />
              <Youtube src={youtubeProps.src} className={youtubeProps.className} />
              <Linkedin src={linkedinProps.src} className={linkedinProps.className} />
              <Github className={githubProps.className} />
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;

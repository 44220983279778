import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import Button04 from '../Button04';
import Footer from '../Footer';
import './LrgMeetTheRobots.scss';
import MblMeetTheRobots from '../MblMeetTheRobots';
import Ns0Image from '../../static/img/mask-group-5@1x.png';
import maskGroup6 from '../../static/img/mask-group-6@1x.png';
import maskGroup7 from '../../static/img/mask-group-7@1x.png';

const button02Data = {
  className: 'button-0-1-1',
};

const button03Data = {
  className: 'mmtr-button-0-3',
};

const button04Data = {
  className: 'button-0-1-2',
};

const button05Data = {
  className: 'button-0',
};

const button06Data = {
  className: 'button-0-1',
};

const googlePixel6ProMeetTheRobotsData = {
  title: (
    <React.Fragment>
      NetShape Robots, <br />
      your new team of finishers.
    </React.Fragment>
  ),

  spanText1: (
    <React.Fragment>
      EDUCATION
      <br />
    </React.Fragment>
  ),
  spanText2: 'NS0',
  spanText3: '',
  spanText4: (
    <React.Fragment>
      The NS0 is designed primarily for educational and academic environments,
      offering an affordable platform to delve into and experience cutting-edge
      digital manufacturing software. It comes equipped with the top-tier
      Netshape control, advanced sensing and instructional capabilities.
      <br /><br />
      Ideal for tasks like support removal, cutting, grinding, finishing, and
      polishing demonstrations and education, the NS0 seamlessly integrates with
      prevalent CAD formats and can manage components weighing up to 150kg
    </React.Fragment>
  ),
  spanText6: '.',
  maskGroup6: '/img/mask-group-6@1x.png',
  spanText7: (
    <React.Fragment>
      PRODUCTION
      <br />
    </React.Fragment>
  ),
  spanText8: 'NS1',
  spanText9: '',
  spanText10: (
    <React.Fragment>
      The NS1 is tailored for those transitioning into full-scale production, adeptly handling tasks like support removal, finishing, polishing, and blasting. With its dual finishing zones, it promotes efficiency by allowing simultaneous finishing on one side while loading and unloading on the other. Boasting a compatibility with parts weighing up to 600kg and dimensions of 800mm x 650mm x 600mm, the NS1 is versatile and material-agnostic, ensuring a wide range of applications.{' '}
    </React.Fragment>
  ),
  maskGroup7: '/img/mask-group-7@1x.png',
  spanText13: '',
  spanText14: (
    <React.Fragment>
      The NS2 elevates the capabilities of its predecessor, the NS1, by seamlessly integrating 8 + 8 axis parallel processing. This advanced feature is achieved by harnessing the power of two robots operating simultaneously. Whether it's inspection, handling, pick & place actions, fixturing, cleaning, or concurrent machining, the NS2 ensures unparalleled efficiency and precision. It's the optimal solution for those seeking to maximize productivity and finesse in their operations.
    </React.Fragment>
  ),
  spanText15: (
    <React.Fragment>
      DUAL PRODUCTION
      <br />
    </React.Fragment>
  ),
  spanText16: 'NS2',
  button01Props: button02Data,
  button02Props: button03Data,
  button03Props: button04Data,
  button04Props: button05Data,
  button05Props: button06Data,
};

class LrgMeetTheRobots extends React.Component {
  render() {
    const {
      place,
      anglesRight,
      spanText1,
      spanText2,
      prototyping,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      title,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      factoryProduction,
      spanText11,
      spanText12,
      inFieldProduction,
      headerLargeDesktopProps,
      heroLargeDesktop2Props,
      button021Props,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
          />
          <title>Meet The Robots | Rivelin</title>
          <meta
            name='description'
            content='Learn about your new team of finishers and how they help you go from prototyping to production in metal AM'
          />
          <meta
            name='keywords'
            content='metal AM, metal additive manufacturing finishing, metal additive manufacturing support removal, metal additive manufacturing post processing, metal additive manufacturing powder removal, metal additive manufacturing automation, NetShape, Rivelin'
          />
          <meta
            property='og:description'
            content='Learn about your new team of finishers and how they help you go from prototyping to production in metal AM'
          />
          <meta property='og:site_name' content='Rivelin Robotics' />
          <meta property='og:title' content='Meet The Robots' />
          <meta name='og:image' content='https://www.rivelinrobotics.com/img/mask-group-6@1x.png' />
          <meta
            name='og:image:secure_url'
            content='https://www.rivelinrobotics.com/img/mask-group-6@1x.png'
          />
          <meta
            name='image'
            property='og:image'
            content='https://www.rivelinrobotics.com/img/mask-group-6@1x.png'
          />
          <meta property='og:image:type' content='image/png' />
        </Helmet>
        <div className='lrg-meet-the-robots screen'>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
            className={heroLargeDesktop2Props.className}
          />
          <div className='nav-bar-2'>
            <HashLink to='/#top'>
              <div className='place-1 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right' src={anglesRight} alt={anglesRight} />
            <div className='who-we-are-placeholder atkinsonhyperlegible-normal-onyx-22px-2'>
              <span className='span0-2 atkinsonhyperlegible-normal-onyx-22px'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText2}</span>
            </div>
          </div>
          <MblMeetTheRobots {...googlePixel6ProMeetTheRobotsData} />
          <div className='mtr-shrinker'>
            <div className='page-stack'>
              <div className='overlap-group6'>
                <div className='group-container'>
                  <img className='mask-group-5' src={Ns0Image} alt={Ns0Image} />
                  <div className='group-35'>
                    <div className='prototyping atkinsonhyperlegible-normal-bunker-26px'>{prototyping}</div>
                    <div className='ns-0the-ns-0-takes atkinsonhyperlegible-normal-onyx-50px'>
                      <span className='span0-1 atkinsonhyperlegible-bold-bunker-50px'>{spanText3}</span>
                      <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText4}</span>
                      <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText5}</span>
                      <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText6}</span>
                    </div>
                    <HashLink to='/contact-us#top'>
                      <div className='net-shape-1'>
                        <div className='button-container-1'>
                          <Button04 />
                          <Button04 className={button021Props.className} />
                        </div>
                      </div>
                    </HashLink>
                  </div>
                </div>
                <div className='title-funky atkinsonhyperlegible-bold-bunker-40px'>{title}</div>
              </div>
              <div className='ns-1'>
                <div className='overlap-group4'>
                  <img className='mask-group-6' src={maskGroup6} alt={maskGroup6} />
                  <div className='ns-1the-ns-1-takes atkinsonhyperlegible-normal-onyx-50px'>
                    <span className='span0-1 atkinsonhyperlegible-bold-bunker-50px'>{spanText7}</span>
                    <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText8}</span>
                    <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText9}</span>
                    <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText10}</span>
                  </div>
                </div>
                <div className='factory-production atkinsonhyperlegible-normal-bunker-26px'>
                  {factoryProduction}
                </div>
                <HashLink to='/contact-us#top'>
                  <div className='button-container-2'>
                    <Button04 />
                    <Button04 className={button021Props.className} />
                  </div>
                </HashLink>
              </div>
              <div className='ns-2'>
                <div className='overlap-group3'>
                  <img className='mask-group-7' src={maskGroup7} alt={maskGroup7} />
                  <div className='ns-2the-ns-2-takes atkinsonhyperlegible-normal-onyx-50px'>
                    <span className='span0-1 atkinsonhyperlegible-bold-bunker-50px'>{spanText11}</span>
                    <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText12}</span>
                  </div>
                </div>
                <div className='in-field-production atkinsonhyperlegible-normal-bunker-26px'>
                  {inFieldProduction}
                </div>
                <HashLink to='/contact-us#top'>
                  <div className='net-shape-2'>
                    <div className='button-container-1'>
                      <Button04 />
                      <Button04 className={button021Props.className} />
                    </div>
                  </div>
                </HashLink>
              </div>
            </div>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default LrgMeetTheRobots;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import PrimaryButton2 from '../PrimaryButton2';
import MblHomePage from '../MblHomePage';
import Button0 from '../Button0';
import Button1 from '../../static/img/base-1@1x.png';
import mobButton from '../../static/img/base@1x.png';
import Footer from '../Footer';
import './LrgHomePage.scss';
import '../../components/HeroLargeDesktop/HeroLargeDesktop.scss';

const homeHeroMobileData = {
  homeHeroMobile: '/img/mask-group-9@1x.png',
  spanText1: 'AUTOMATED, RELIABLE & REPEATABLE',
  spanText2: 'POST PROCESSING SOLUTIONS',
  spanText3: 'FOR MANUFACTURERS LOOKING TO HARNESS THE REAL POTENTIAL OF METAL AM',
  spanText4:
    "The automation of common metal AM post processing tasks — such as support removal & surface finishing – protects workers' health, tackles skill shortages and reduces costs.",
  withRivelinSNetsh:
    'RIVELIN NETSHAPE® ROBOTS REDUCE THE BURDEN OF POST PROCESSING IN NEAR-NET SHAPE METAL MANUFACTURING',
};

const mblbutton11Data = {
  button1: mobButton,
};

const mblbutton12Data = {
  button1: Button1,
  className: 'button-1-1',
};

const mblbutton02Data = {
  className: 'button-0-1',
};

const googlePixel6ProData = {
  homeHeroMobileProps: homeHeroMobileData,
  button11Props: mblbutton11Data,
  button12Props: mblbutton12Data,
  button0Props: mblbutton02Data,
};

class LrgHomePage extends React.Component {
  render() {
    const {
      headerLargeDesktopProps,
      primaryButton21Props,
      primaryButton22Props,
      button0Props,
      footerProps,
      gkn_2,
      renishaw_White,
      oerlikon,
      ministryofdefence_White,
    } = this.props;

    return (
      <div className='lrg-home-page screen'>
        <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
          />
          <title>Home | Rivelin</title>
          <meta
            name='description'
            content="The automation of common metal AM post processing tasks — such as support removal & surface finishing – protects workers' health, tackles skill shortages and reduces costs."
          />
          <meta
            name='keywords'
            content='metal AM, metal additive manufacturing finishing, metal additive manufacturing support removal, metal additive manufacturing post processing, metal additive manufacturing powder removal, metal additive manufacturing automation, NetShape, Rivelin'
          />
          <meta
            property='og:description'
            content="The automation of common metal AM post processing tasks — such as support removal & surface finishing – protects workers' health, tackles skill shortages and reduces costs."
          />
          <meta property='og:site_name' content='Rivelin Robotics' />
          <meta
            property='og:title'
            content='Reliable metal AM production lines finish with reliable NetShape Robots.'
          />
          <meta name='og:image' content='https://www.rivelinrobotics.com/img/EWIRAbracket.png' />
          <meta name='og:image:secure_url' content='https://www.rivelinrobotics.com/img/EWIRAbracket.png' />
          <meta
            name='image'
            property='og:image'
            content='https://www.rivelinrobotics.com/img/EWIRAbracket.png'
          />
          <meta property='og:image:type' content='image/png' />
        </Helmet>
        <HeaderLargeDesktop {...headerLargeDesktopProps} />
        <MblHomePage
          oerlikon='/img/amrc_white.png'
          gkn_2='/img/GKN_2.png'
          renishaw_White='/img/Renishaw_white.png'
          ministryofdefence_White='/img/MinistryOfDefence_white.png'
          homeHeroMobileProps={googlePixel6ProData.homeHeroMobileProps}
          button11Props={googlePixel6ProData.button11Props}
          button12Props={googlePixel6ProData.button12Props}
          button0Props={googlePixel6ProData.button0Props}
        />
        <div className='video-container'>
          <video autoPlay loop muted id='hero-vid'>
            <source
              type='video/mp4'
              src='https://storage.googleapis.com/www.old.additiveautomations.com/HomepageVideo.mp4'
            />
          </video>
          <div className='video-overlay'>
            <span className='overlay-big'>
              For manufacturers looking to harness the <span className='lime-green'>real</span> potential of{' '}
              <span className='lime-green'>metal AM</span>
            </span>
            <span className='overlay-small'>
              Rivelin Netshape® robots reduce the burden of{' '}
              <span className='lime-green'>post-processing</span> in near-net-shape metal manufacturing
            </span>
            <div className='hero-links'>
              <HashLink to='/meet-the-robots#top'>
                <div className='link'>
                  <div className='button-container'>
                    <PrimaryButton2
                      primaryButton={primaryButton21Props.primaryButton}
                      openPositions={primaryButton21Props.openPositions}
                    />
                    <PrimaryButton2
                      primaryButton={primaryButton22Props.primaryButton}
                      openPositions={primaryButton22Props.openPositions}
                      className={primaryButton22Props.className}
                    />
                  </div>
                </div>
              </HashLink>
              <HashLink to='/learn-the-tech#top'>
                <div className='link'>
                  <div className='button-container'>
                    <Button0 />
                    <Button0 className={button0Props.className} />
                  </div>
                </div>
              </HashLink>
            </div>
          </div>
        </div>
        <div className='hero-banner'>
          <div className='group-21'>
            <div className='hero-l-container'>
              <div className='hero-large-desktop'>
                <div className='title'>
                  <span>AUTOMATED, RELIABLE & REPEATABLE POST PROCESSING SOLUTIONS</span>
                  <br />
                </div>
                <div className='sub-title'>
                  The automation of common metal AM post processing tasks — such as support removal & surface
                  finishing – protects workers' health, tackles skill shortages and reduces costs.
                </div>
              </div>
              <div className='render-container'>
                <video autoPlay loop muted playsInline id='render-vid'>
                  <source
                    type='video/mp4'
                    src='https://storage.googleapis.com/www.old.additiveautomations.com/ProductAnimations.mp4'
                  />
                </video>
              </div>
              <div className='flex-row-home-desktop'>
                <img className='oerlikon' src={oerlikon} alt={oerlikon} />
                <img className='gkn_2' src={gkn_2} alt={gkn_2} />
                <img className='renishaw_white' src={renishaw_White} alt={renishaw_White} />
                <img
                  className='ministry-of-defence_white'
                  src={ministryofdefence_White}
                  alt={ministryofdefence_White}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='faq'>
          <span className='atkinsonhyperlegible-normal-white-17px'>
            <span className='atkinsonhyperlegible-bold-white-26px'>Q:</span> Why have the likes of GKN
            Aerospace and the Ministry of Defence invested in the NetShape Robot support removal & surface
            finishing solution from Rivelin Robotics?
            <br />
            <br />
            <span className='atkinsonhyperlegible-bold-white-26px'>A:</span> They understand the pressing need
            to incorporate metal additive manufacturing in their production lines while at the same time
            overcoming the issues of post processing by hand, which leads to a lack of repeatability, lack of
            traceability, spiralling costs, limited manpower and risks to personnel.
            <br />
            <br />
            Rivelin Robotics uses robotics technology that is specific to near-net shape manufacturing. We use
            state-of-the-art sensors and processes to bring dexterity, versatility, and ease of operation to
            industrial robotic arms. The result when removing AM supports & surface finishing is:
            <br />
            <br />
            <ul>
              <li>90% reduction in defects</li>
              <li>10-fold reduction in operational cost</li>
              <li>Elimination of human risk and variability.</li>
            </ul>
          </span>
        </div>
        <Footer
          privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
          youtubeProps={footerProps.youtubeProps}
          linkedinProps={footerProps.linkedinProps}
          githubProps={footerProps.githubProps}
        />
      </div>
    );
  }
}

export default LrgHomePage;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import Footer from '../Footer';
import './LrgSeeItInAction.scss';

class LSeeItInAction extends React.Component {
  render() {
    const {
      place,
      anglesRight,
      spanText1,
      spanText2,
      supportRemovalLPbf,
      netshapeRobotsCan,
      finishingLbpf,
      complexAndFreeFor,
      headerLDesktopAltProps,
      heroLargeDesktop2Props,
      supportRemovalEBM1Props,
      supportRemovalEBM2Props,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
          />
          <title>See It In Action | Rivelin</title>
          <meta
            name='description'
            content='Seeing is believing. Watch our robots autonomously finish several example parts'
          />
          <meta
            name='keywords'
            content='metal AM, metal additive manufacturing finishing, metal additive manufacturing support removal, metal additive manufacturing post processing, metal additive manufacturing powder removal, metal additive manufacturing automation, NetShape, Rivelin'
          />
          <meta
            property='og:description'
            content='Seeing is believing. Watch our robots autonomously finish several example parts'
          />
          <meta property='og:site_name' content='Rivelin Robotics' />
          <meta property='og:title' content='See It In Action' />
          <meta name='og:image' content='https://www.rivelinrobotics.com/img/EWIRAbracket.png' />
          <meta name='og:image:secure_url' content='https://www.rivelinrobotics.com/img/EWIRAbracket.png' />
          <meta
            name='image'
            property='og:image'
            content='https://www.rivelinrobotics.com/img/EWIRAbracket.png'
          />
          <meta property='og:image:type' content='image/png' />
        </Helmet>
        <div className='l-see-it-in-action screen'>
          <HeaderLargeDesktop {...headerLDesktopAltProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
            className={heroLargeDesktop2Props.className}
          />
          <div className='nav-bar-12'>
            <HashLink to='/#top'>
              <div className='place-13 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right-2' src={anglesRight} alt={anglesRight} />
            <div className='who-we-are-placeholder-2 atkinsonhyperlegible-normal-onyx-22px-2'>
              <span className='span0-5 atkinsonhyperlegible-normal-onyx-22px'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText2}</span>
            </div>
          </div>
          <div className='videos'>
            <div className='video-content-wrapper'>
              <ReactPlayer
                className='video-player-root'
                url='https://storage.googleapis.com/www.old.additiveautomations.com/HidraulicManifoldSupportRemoval.mp4'
                controls={true}
              />
              <div className='video-text-wrapper'>
                <div className='video-title atkinsonhyperlegible-bold-bunker-40px'>{supportRemovalLPbf}</div>
                <div className='video-description atkinsonhyperlegible-normal-onyx-26px'>
                  {netshapeRobotsCan}
                </div>
              </div>
            </div>
            <div className='video-content-wrapper'>
              <ReactPlayer
                className='video-player-root'
                url='https://storage.googleapis.com/www.old.additiveautomations.com/AerospaceEWIRABracket2Min.mp4'
                controls={true}
              />
              <div className='video-text-wrapper'>
                <div className='video-title atkinsonhyperlegible-bold-bunker-40px'>
                  {supportRemovalEBM1Props.supportRemovalEbm}
                </div>
                <div className='video-description atkinsonhyperlegible-normal-onyx-26px'>
                  {supportRemovalEBM1Props.autonomousFinishing}
                </div>
              </div>
            </div>
            <div className='video-content-wrapper'>
              <ReactPlayer
                className='video-player-root'
                url='https://storage.googleapis.com/www.old.additiveautomations.com/DefenceSatelliteBracket3Min.mp4'
                controls={true}
              />
              <div className='video-text-wrapper'>
                <div className='video-title atkinsonhyperlegible-bold-bunker-40px'>{finishingLbpf}</div>
                <div className='video-description atkinsonhyperlegible-normal-onyx-26px'>
                  {complexAndFreeFor}
                </div>
              </div>
            </div>
            <div className='video-content-wrapper'>
              <ReactPlayer
                className='video-player-root'
                url='https://storage.googleapis.com/www.old.additiveautomations.com/MedicalCranialPlate2Min.mp4'
                controls={true}
              />
              <div className='video-text-wrapper'>
                <div className='video-title atkinsonhyperlegible-bold-bunker-40px'>
                  {supportRemovalEBM2Props.supportRemovalEbm}
                </div>
                <div className='video-description atkinsonhyperlegible-normal-onyx-26px'>
                  {supportRemovalEBM2Props.autonomousFinishing}
                </div>
              </div>
            </div>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default LSeeItInAction;

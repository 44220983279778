import React from "react";
import "./Button0.scss";

class Button0 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`mmtr-button-0-2 ${className || ""}`}>
        <div className="overlap-group">
          <div className="mmtr-rectangle-450"></div>
          <div className="connect-with-an-engineer atkinsonhyperlegible-bold-black-12px">CONNECT WITH AN ENGINEER</div>
        </div>
      </div>
    );
  }
}

export default Button0;

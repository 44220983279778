import React from "react";
import { HashLink } from "react-router-hash-link";
import "./SmallTopLink.scss";

class SmallTopLink extends React.Component {
  render() {
    const { children, className, link } = this.props;

    return (
      <HashLink to={link} className="align-self-flex-end">
        <div className={`small-top-link-wrapper ${className || ""}`}>
          <div className="small-top-link atkinsonhyperlegible-normal-white-17px">{children}</div>
        </div>
      </HashLink>
    );
  }
}

export default SmallTopLink;

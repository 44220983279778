import React from 'react';
import './Button03.scss';

class Button03 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`button-0-3-1 ${className || ''}`}>
        <div className='overlap-group-13'>
          <div className='rectangle-450-1'></div>
          <div className='our-team-2 atkinsonhyperlegible-bold-bunker-18px'>OUR TEAM</div>
        </div>
      </div>
    );
  }
}

export default Button03;

import React from 'react';
import './Twitter.scss';

class Twitter extends React.Component {
  render() {
    return (
      <a
        href='https://twitter.com/rivelinrobotics'
        target='_blank'
        rel='noopener noreferrer'
        title='Visit twitter Channel'
      >
        <div className='twitter'></div>
      </a>
    );
  }
}

export default Twitter;

import React from "react";
import { HashLink } from "react-router-hash-link";
import "./PrimaryButton.scss";

class PrimaryButton extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <HashLink to="/request-a-demo#top">
        <div className={`primary-button ${className || ""}`} id="primary-button">
          <div className="request-a-demo-12 atkinsonhyperlegible-bold-white-20px">REQUEST A DEMO</div>
        </div>
      </HashLink>
    );
  }
}

export default PrimaryButton;

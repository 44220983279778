import React from 'react';
import './SearchBar.scss';

class SearchBar extends React.Component {
  render() {
    return <div className='search-bar' id='search'></div>;
  }
}

export default SearchBar;

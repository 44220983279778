import React from 'react';
import './MobileSeacrch.scss';

class MobileSearch extends React.Component {
  render() {
    return <div className='search-bar' id='mobile-search'></div>;
  }
}

export default MobileSearch;

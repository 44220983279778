import React from "react";
import "./Problem.scss";

class Problem extends React.Component {
  render() {
    const { problem, additiveManufacturi, className } = this.props;

    return (
      <div className={`problem ${className || ""}`}>
        <div className="problem-1 atkinsonhyperlegible-bold-onyx-24px">{problem}</div>
        <div className="additive-manufacturi atkinsonhyperlegible-normal-onyx-22px">{additiveManufacturi}</div>
      </div>
    );
  }
}

export default Problem;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './DropdownLink.scss';
import roboticFinishingSystemImage from '../../static/img/mask-group-7-1@1x.png';

class DropdownLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayingDropdown: false,
    };
  }

  onFocus = (e) => {
    this.setState({ displayingDropdown: true });
    e.preventDefault();
  };

  onBlur = (e) => {
    this.setState({ displayingDropdown: false });
  };

  render() {
    const { children, className } = this.props;

    return (
      <div
        className={`dropdown-wrapper ${className || ''}`}
        tabIndex='0'
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      >
        <div className='overlap-group'>
          <div className='dropdown-header atkinsonhyperlegible-bold-white-23px'>
            {children}
            <div className='animation hidden'>
              <div className='rectangle-452'></div>
            </div>
          </div>
        </div>
        {this.state.displayingDropdown && (
          <div className='dropdown'>
            <div className='dropdown-relative'>
              <div className='d-flex-col'>
                <img
                  className='d-mask-group-7'
                  src={roboticFinishingSystemImage}
                  alt={roboticFinishingSystemImage}
                />
                <div className='d-reliable-metal-am-pr atkinsonhyperlegible-normal-white-26px'>
                  <span className='d-span0'>
                    Reliable metal AM productions lines finish with reliable & fast
                  </span>
                  <span className='atkinsonhyperlegible-bold-white-26px'>{' NetShape Robots'}</span>
                </div>
              </div>
              <div className='d-overlap-group-container'>
                <HashLink to='/meet-the-robots#top'>
                  <div className='d-overlap-group2'>
                    <div className='d-color-block' />
                    <h1 className='d-title atkinsonhyperlegible-bold-white-36px'>Meet The Robots</h1>
                    <div className='d-learn-about-your-new atkinsonhyperlegible-normal-white-20px'>
                      Learn about your new team of finishers and how they help you go from prototyping to
                      production in metal AM.
                    </div>
                  </div>
                </HashLink>
                <HashLink to='/learn-the-tech#top'>
                  <div className='d-overlap-group'>
                    <div className='d-color-block' />
                    <div className='d-learn-the-technology atkinsonhyperlegible-bold-white-36px'>
                      Learn The Technology
                    </div>
                    <div className='d-our-superpower-is-ne'>
                      <span className='atkinsonhyperlegible-normal-white-20px'>
                        Our superpower is NetShape
                      </span>
                      <span className='atkinsonhyperlegible-normal-white-20px atkinsonhyperlegible-super'>
                        ®
                      </span>
                      <span className='atkinsonhyperlegible-normal-white-20px'>
                        {' '}
                        and the team behind it. Proprietary AI enables NetShape Robots to adapt to
                        variability.
                      </span>
                    </div>
                  </div>
                </HashLink>
                <HashLink to='/see-it-in-action#top'>
                  <div className='d-overlap-group1'>
                    <div className='d-color-block' />
                    <div className='d-see-it-in-action atkinsonhyperlegible-bold-white-36px'>
                      See It In Action
                    </div>
                    <div className='d-seeing-is-believing atkinsonhyperlegible-normal-white-20px'>
                      Seeing is believing. Watch our robots autonomously finish several example parts.
                    </div>
                  </div>
                </HashLink>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DropdownLink;

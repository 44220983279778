import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './LargeHeaderLink.scss';

class LargeHeaderLink extends React.Component {
  render() {
    const { children, className, link } = this.props;

    return (
      <div className={`large-header-link-wrapper ${className || ''}`}>
        <div className='overlap-group'>
          <HashLink to={link}>
            <div className='large-header-link atkinsonhyperlegible-bold-white-23px'>{children}</div>
            <div className='animation hidden'>
              <div className='rectangle-452'></div>
            </div>
          </HashLink>
          {/*<a href="javascript:ShowOverlay('header-menu', 'animate-appear');">*/}
          {/*</a>*/}
        </div>
      </div>
    );
  }
}

export default LargeHeaderLink;

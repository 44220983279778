import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import Button03 from '../Button03';
import PrimaryButton3 from '../PrimaryButton3';
import ApplyNow from '../ApplyNow';
import Footer from '../Footer';
import './LrgWhoWeAre.scss';
import MblWhoWeAre from '../MblWhoWeAre';
import overlayGroup1 from '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3-@1x.png';
import overlayGroup1Image from '../../static/img/robertbush-nobkg1-1@1x.png';
import overlap from '../../static/img/rivelintortuga@1x.png';
import overlay2 from '../../static/img/dalatorre-nobkg@1x.png';
import overlayLinkdin from '../../static/img/linkedin-1@1x.png';
import overlayGroup2 from '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3--1@1x.png';
import overlayGroup3 from '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3--2@1x.png';
import overlayGroup3Image from '../../static/img/davidmason-nobkg1@1x.png';
import overlay4 from '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3--3@1x.png';
import overlayGroup4 from '../../static/img/ianhalliday-nobkg1@1x.png';
import angleRight from '../../static/img/angles-right-1@1x.png';

const primaryButton26Data = {
  className: 'button-3',
};

const button032Data = {
  className: 'button-0-4',
};

const nS1Data = {
  spanText1: (
    <React.Fragment>
      REIMAGINE A WORLD WITHOUT WASTE
      <br />
    </React.Fragment>
  ),
  spanText2: 'We exist to drive the mass uptake of metal AM across industry.',
};

const googlePixel6ProWhoWeAreData = {
  title: 'Factories of the future, today',
  weAreRivelinWeA: (
    <React.Fragment>
      We are Rivelin.
      <br />
      We are here to evolve industry.
      <br />
      Evolve to a world driven by digitalisation and the need for sustainable manufacturing.
      <br />
      Today we&#x27;re focused on metal additive manufacturing support removal and finishing but that&#x27;s
      only the beginning.
    </React.Fragment>
  ),
  spanText1: (
    <React.Fragment>
      Past
      <br />
    </React.Fragment>
  ),
  spanText2: (
    <React.Fragment>
      <br />
      Rivelin Robotics was founded by Robert Bush while working as metals production engineer at 3T-AM
      (BEAMIT) after battling with the challenge of getting the first metal AM part through PPAP (Production
      Part Approval Process).
      <br />
      <br />
    </React.Fragment>
  ),
  spanText3: (
    <React.Fragment>
      Present
      <br />
      <br />
    </React.Fragment>
  ),
  spanText4: (
    <React.Fragment>
      Our robotic finishing systems are empowering engineers to automate support removal and polishing to meet
      the standards of mission-critical production lines.
      <br />
      <br />
    </React.Fragment>
  ),
  spanText5: (
    <React.Fragment>
      Future
      <br />
    </React.Fragment>
  ),
  spanText6: (
    <React.Fragment>
      <br />
      Autonomous post processing for metal AM production parts, spare parts and the repair of parts so local
      manufacturers everywhere can remake the way we make things and accomplish a sustainable manufacturing
      industry within our lifetime.
    </React.Fragment>
  ),
  maskGroup8: '../../static/img/mask-group-8@1x.png',
  inASystemWhereCo: (
    <React.Fragment>
      In a system where companies are being held accountable for their carbon footprint, the linear story is
      evolving to a circular one. Our people see metal AM as a major contributor to a sustainable future, and
      which will have lasting benefits for our environment. With NetShape Robots we introduce our first
      breakthrough to drive the uptake of metal AM.
    </React.Fragment>
  ),
  overlapGroup2: '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3-@1x.png',
  robertbush_Nobkg1: '../../static/img/robertbush-nobkg1-1@1x.png',
  iconLinkedin1: '../../static/img/linkedin-1@1x.png',
  spanText7: (
    <React.Fragment>
      Robert Bush
      <br />
    </React.Fragment>
  ),
  spanText8: 'Chief Executive Officer',
  overlapGroup3: '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3--1@1x.png',
  dalatorre_Nobkg: '../../static/img/dalatorre-nobkg@1x.png',
  iconLinkedin2: '../../static/img/linkedin-1@1x.png',
  spanText9: (
    <React.Fragment>
      David Alatorre
      <br />
    </React.Fragment>
  ),
  spanText10: 'Chief Technical Officer',
  timHufner3Wcnxptrr50Unsplash3: '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3--2@1x.png',
  davidmason_Nobkg1: '../../static/img/davidmason-nobkg1@1x.png',
  iconLinkedin3: '../../static/img/linkedin-1@1x.png',
  spanText11: (
    <React.Fragment>
      David Mason
      <br />
    </React.Fragment>
  ),
  spanText12: 'Chief Product Officer',
  overlapGroup5: '/img/tim-hufner-3wcnxptrr50-unsplash--3--3@1x.png',
  ianhalliday_Nobkg1: '../../static/img/ianhalliday-nobkg1@1x.png',
  iconLinkedin4: '../../static/img/linkedin-1@1x.png',
  spanText13: (
    <React.Fragment>
      Ian Halliday
      <br />
    </React.Fragment>
  ),
  spanText14: 'Investor & Advisor',
  button031Props: button032Data,
  primaryButton21Props: primaryButton26Data,
  nS1Props: nS1Data,
};

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -200;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

class LrgWhoWeAre extends React.Component {
  render() {
    const {
      place,
      spanText1,
      spanText2,
      factoriesOfTheFutureToday,
      weAreRivelinWeA,
      past,
      rivelinRoboticsWas,
      present,
      ourRoboticFinishin,
      future,
      autonomousPostProc,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      spanText11,
      spanText12,
      inASystemWhereCo,
      headerLargeDesktopProps,
      heroLargeDesktop2Props,
      button031Props,
      applyNowProps,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
          />
          <title>Who We Are | Rivelin</title>
          <meta name='description' content='Factories of the future, today' />
          <meta
            name='keywords'
            content='metal AM, metal additive manufacturing finishing, metal additive manufacturing support removal, metal additive manufacturing post processing, metal additive manufacturing powder removal, metal additive manufacturing automation, NetShape, Rivelin'
          />
          <meta property='og:description' content='Factories of the future, today' />
          <meta property='og:site_name' content='Rivelin Robotics' />
          <meta property='og:title' content='Who We Are' />
          <meta name='og:image' content='https://www.rivelinrobotics.com/img/EWIRAbracket.png' />
          <meta name='og:image:secure_url' content='https://www.rivelinrobotics.com/img/EWIRAbracket.png' />
          <meta
            name='image'
            property='og:image'
            content='https://www.rivelinrobotics.com/img/EWIRAbracket.png'
          />
          <meta property='og:image:type' content='image/png' />
        </Helmet>
        <div className='lrg-who-we-are screen'>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
            className={heroLargeDesktop2Props.className}
          />
          <div className='nav-bar-13'>
            <HashLink to='/#top'>
              <div className='place-2 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right-1' src={angleRight} alt={angleRight} />
            <div className='who-we-are-placeholder atkinsonhyperlegible-normal-onyx-22px-2'>
              <span className='span0-5 atkinsonhyperlegible-normal-onyx-22px'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText2}</span>
            </div>
          </div>
          <MblWhoWeAre {...googlePixel6ProWhoWeAreData} />
          <div className='wwa-shrinker'>
            <div className='top-copy'>
              <div className='heading-1-1'>
                <div className='factories-of-the-future-today atkinsonhyperlegible-bold-bunker-40px'>
                  {factoriesOfTheFutureToday}
                </div>
                <div className='we-are-rivelinwe-a atkinsonhyperlegible-normal-bunker-24px'>
                  {weAreRivelinWeA}
                </div>
                <div className='overlap-group-container-2'>
                  <HashLink smooth to='/who-we-are#team-rb' scroll={(el) => scrollWithOffset(el)}>
                    <div className='button-container-4'>
                      <Button03 />
                      <Button03 className={button031Props.className} />
                    </div>
                  </HashLink>
                  <HashLink smooth to='/careers#open-positions' scroll={(el) => scrollWithOffset(el)}>
                    <div className='primary-button-container-13'>
                      <PrimaryButton3 />
                      <PrimaryButton3 className='primary-button-24' />
                    </div>
                  </HashLink>
                </div>
              </div>
              <div className='past-present-future'>
                <div className='past atkinsonhyperlegible-bold-onyx-32px'>{past}</div>
                <div className='past-present-future-item atkinsonhyperlegible-normal-onyx-24px'>
                  {rivelinRoboticsWas}
                </div>
                <div className='past-present-future-item-1 atkinsonhyperlegible-bold-onyx-32px'>
                  {present}
                </div>
                <div className='past-present-future-item atkinsonhyperlegible-normal-onyx-24px'>
                  {ourRoboticFinishin}
                </div>
                <div className='past-present-future-item-1 atkinsonhyperlegible-bold-onyx-32px'>{future}</div>
                <div className='past-present-future-item atkinsonhyperlegible-normal-onyx-24px'>
                  {autonomousPostProc}
                </div>
              </div>
            </div>
            <div className='overlap-group6'>
              <div className='team-rb' id='team-rb'>
                <div className='overlap-group-27' style={{ backgroundImage: `url(${overlayGroup1})` }}>
                  <img className='_nobkg' src={overlayGroup1Image} alt={overlayGroup1Image} />
                  <a
                    href='https://www.linkedin.com/in/bushrobertj'
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Visit linkedin Channel'
                  >
                    <img className='icon-linkedin' src={overlayLinkdin} alt={overlayLinkdin} />
                  </a>
                </div>
                <div className='name-1 atkinsonhyperlegible-normal-onyx-36px'>
                  <span className='atkinsonhyperlegible-normal-onyx-36px-2'>{spanText3}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText4}</span>
                </div>
              </div>
              <div className='team-da'>
                <div className='overlap-group-27' style={{ backgroundImage: `url(${overlayGroup2})` }}>
                  <img className='_nobkg' src={overlay2} alt={overlay2} />
                  <a
                    href='https://www.linkedin.com/in/alatorredavid/'
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Visit linkedin Channel'
                  >
                    <img className='icon-linkedin' src={overlayLinkdin} alt={overlayLinkdin} />
                  </a>
                </div>
                <div className='name-1 atkinsonhyperlegible-normal-onyx-36px'>
                  <span className='atkinsonhyperlegible-normal-onyx-36px-2'>{spanText5}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText6}</span>
                </div>
              </div>
              <div className='team-dm'>
                <div className='overlap-group3-2'>
                  <img
                    className='tim-hufner-3-wcnx-ptrr50-unsplash-3'
                    src={overlayGroup3}
                    alt={overlayGroup3}
                  />
                  <img className='david-mason_nobkg1' src={overlayGroup3Image} alt={overlayGroup3Image} />
                  <a
                    href='https://www.linkedin.com/in/david-mason-67628599/'
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Visit linkedin Channel'
                  >
                    <img className='icon-linkedin-1' src={overlayLinkdin} alt={overlayLinkdin} />
                  </a>
                </div>
                <div className='name-2 atkinsonhyperlegible-normal-onyx-36px'>
                  <span className='atkinsonhyperlegible-normal-onyx-36px-2'>{spanText7}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText8}</span>
                </div>
              </div>
              <div className='team-ih'>
                <div className='overlap-group-27' style={{ backgroundImage: `url(${overlay4})` }}>
                  <img className='_nobkg' src={overlayGroup4} alt={overlayGroup4} />
                  <a
                    href='https://www.linkedin.com/in/dr-ian-halliday-1651978/'
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Visit linkedin Channel'
                  >
                    <img className='icon-linkedin' src={overlayLinkdin} alt={overlayLinkdin} />
                  </a>
                </div>
                <div className='name-1 atkinsonhyperlegible-normal-onyx-36px'>
                  <span className='atkinsonhyperlegible-normal-onyx-36px-2'>{spanText9}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText10}</span>
                </div>
              </div>
              <div className='why-we-exist-copy'>
                <div className='overlap-group5'>
                  <img className='rivelin-tortuga-5' src={overlap} alt={overlap} />
                  <div className='reimagine-a-world-wi atkinsonhyperlegible-bold-onyx-26px'>
                    <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText11}</span>
                    <span className='atkinsonhyperlegible-bold-onyx-46px'>{spanText12}</span>
                  </div>
                </div>
                <div className='in-a-system-where-co atkinsonhyperlegible-normal-onyx-26px'>
                  {inASystemWhereCo}
                </div>
                <ApplyNow
                  applyNow={applyNowProps.applyNow}
                  className={applyNowProps.className}
                  link={applyNowProps.link}
                />
              </div>
            </div>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default LrgWhoWeAre;

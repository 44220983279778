import React from 'react';
import './Button0.scss';

class Button0 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`button-0 ${className || ''}`}>
        <div className='overlap-group-12'>
          <div className='rectangle-450 border-2px-white'></div>
          <div className='net-shape atkinsonhyperlegible-bold-white-18px'>NetShape®</div>
        </div>
      </div>
    );
  }
}

export default Button0;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import PrimaryButton3 from './PrimaryButton3';
import NS1 from './NS1';
import './MblWhoWeAre.scss';
import ApplyNow from '../ApplyNow';
import Button03 from './Button03';
import maskGroup8 from '../../static/img/mask-group-8@1x.png';
import overlay1 from '../../static/img/tim-hufner-3wcnxptrr50-unsplash--3--1@1x.png';
import overlayImage2 from '../../static/img/robertbush-nobkg1-1@1x.png';
import IconLinldin from '../../static/img/linkedin-1@1x.png';
import Image2 from '../../static/img/dalatorre-nobkg@1x.png';
import image3 from '../../static/img/davidmason-nobkg1@1x.png';
import image4 from '../../static/img/ianhalliday-nobkg1@1x.png';

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

class MblWhoWeAre extends React.Component {
  render() {
    const {
      title,
      weAreRivelinWeA,
      spanText1,
      spanText2,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      inASystemWhereCo,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      spanText11,
      spanText12,
      spanText13,
      spanText14,
      button031Props,
      nS1Props,
    } = this.props;

    return (
      <div className='container-center-horizontal mwwa-container'>
        <div className='google-pixel-6-pro-who-we-are'>
          <div className='mwwa-overlap-group6'>
            <div className='page-stack'>
              <div className='title atkinsonhyperlegible-bold-bunker-27px'>{title}</div>
              <div className='we-are-rivelinwe-a atkinsonhyperlegible-normal-onyx-18px'>
                {weAreRivelinWeA}
              </div>
              <div className='pastrivelin-roboti'>
                <span className='atkinsonhyperlegible-bold-onyx-24px'>{spanText1}</span>
                <span className='atkinsonhyperlegible-normal-onyx-18px'>{spanText2}</span>
                <span className='atkinsonhyperlegible-bold-onyx-24px'>{spanText3}</span>
                <span className='atkinsonhyperlegible-normal-onyx-18px'>{spanText4}</span>
                <span className='atkinsonhyperlegible-bold-onyx-24px'>{spanText5}</span>
                <span className='atkinsonhyperlegible-normal-onyx-18px'>{spanText6}</span>
              </div>
            </div>
            <div className='group-36'>
              <HashLink smooth to='/who-we-are#team-link' scroll={(el) => scrollWithOffset(el)}>
                <div className='button-container'>
                  <Button03 />
                  <Button03 className={button031Props.className} />
                </div>
              </HashLink>
              <HashLink smooth to='/careers#open-positions' scroll={(el) => scrollWithOffset(el)}>
                <div className='primary-button-container'>
                  <PrimaryButton3 />
                  <PrimaryButton3 className='primary-button-24' />
                </div>
              </HashLink>
            </div>
          </div>
          <img className='mask-group-8' src={maskGroup8} alt={maskGroup8} />
          <NS1 spanText1={nS1Props.spanText1} spanText2={nS1Props.spanText2} />
          <div className='mwwa-in-a-system-where-co atkinsonhyperlegible-normal-onyx-18px'>
            {inASystemWhereCo}
          </div>
          <ApplyNow applyNow='LEARN MORE' className='apply-now-2 mbl-learn-more' link='/why-we-exist#top' />
          <div className='mwwa-team-rb'>
            <div className='overlap-group' id='team-link' style={{ backgroundImage: `url(${overlay1})` }}>
              <img className='_nobkg' src={overlayImage2} alt={overlayImage2} />
              <a
                href='https://www.linkedin.com/in/bushrobertj'
                target='_blank'
                rel='noopener noreferrer'
                title='Visit linkedin Channel'
              >
                <img className='icon-linkedin' src={IconLinldin} alt={IconLinldin} />
              </a>
            </div>
            <h1 className='name atkinsonhyperlegible-normal-onyx-29px'>
              <span className='atkinsonhyperlegible-normal-onyx-29px-2'>{spanText7}</span>
              <span className='atkinsonhyperlegible-normal-onyx-21px'>{spanText8}</span>
            </h1>
          </div>
          <div className='mwwa-team-da'>
            <div className='overlap-group' style={{ backgroundImage: `url(${overlay1})` }}>
              <img className='_nobkg' src={Image2} alt={Image2} />
              <a
                href='https://www.linkedin.com/in/alatorredavid/'
                target='_blank'
                rel='noopener noreferrer'
                title='Visit linkedin Channel'
              >
                <img className='icon-linkedin' src={IconLinldin} alt={IconLinldin} />
              </a>
            </div>
            <div className='name atkinsonhyperlegible-normal-onyx-29px'>
              <span className='atkinsonhyperlegible-normal-onyx-29px-2'>{spanText9}</span>
              <span className='atkinsonhyperlegible-normal-onyx-21px'>{spanText10}</span>
            </div>
          </div>
          <div className='mwwa-team-dm'>
            <div className='overlap-group4'>
              <img className='tim-hufner-3-wcnx-ptrr50-unsplash-3' src={overlay1} alt={overlay1} />
              <img className='david-mason_nobkg1' src={image3} alt={image3} />
              <a
                href='https://www.linkedin.com/in/david-mason-67628599/'
                target='_blank'
                rel='noopener noreferrer'
                title='Visit linkedin Channel'
              >
                <img className='icon-linkedin-1' src={IconLinldin} alt={IconLinldin} />
              </a>
            </div>
            <div className='name-1 atkinsonhyperlegible-normal-onyx-29px'>
              <span className='atkinsonhyperlegible-normal-onyx-29px-2'>{spanText11}</span>
              <span className='atkinsonhyperlegible-normal-onyx-21px'>{spanText12}</span>
            </div>
          </div>
          <div className='mwwa-team-ih'>
            <div className='overlap-group5' style={{ backgroundImage: `url(${overlay1})` }}>
              <img className='ianhalliday_nobkg1' src={image4} alt={image4} />
              <a
                href='https://www.linkedin.com/in/dr-ian-halliday-1651978/'
                target='_blank'
                rel='noopener noreferrer'
                title='Visit linkedin Channel'
              >
                <img className='icon-linkedin-2' src={IconLinldin} alt={IconLinldin} />
              </a>
            </div>
            <div className='name atkinsonhyperlegible-normal-onyx-29px'>
              <span className='atkinsonhyperlegible-normal-onyx-29px-2'>{spanText13}</span>
              <span className='atkinsonhyperlegible-normal-onyx-21px'>{spanText14}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MblWhoWeAre;

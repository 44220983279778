import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Button1 from './Button1';
import Button0 from './Button0';
import './MblHomePage.scss';
import screenfull from 'screenfull';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

class MblHomePage extends React.Component {
  state = {
    isIphone: /iPhone/i.test(navigator.userAgent),
  };
  handleVideoPlay = (e) => {
    if (screenfull?.isEnabled) {
      let vidWrapper = document.getElementById('mobile-video-wrapper');
      let vid = document.getElementById('mobile-vid');
      vidWrapper.removeAttribute('hidden');
      screenfull.request(vid);
      screenfull.on('change', () => {
        if (!screenfull.isFullscreen) {
          this.handleVideoClose(e);
        }
      });
    }
  };

  handleVideoClose = (e) => {
    if (screenfull.isEnabled) {
      let vidWrapper = document.getElementById('mobile-video-wrapper');
      vidWrapper.setAttribute('hidden', 'true');
      screenfull.exit();
    }
  };

  render() {
    const { button11Props, button12Props, button0Props } = this.props;
    const { isIphone } = this.state;
    return (
      <div className='container-center-horizontal'>
        <div id='mobile-video-wrapper' hidden>
          <video autoPlay loop muted controls id='mobile-vid'>
            <source
              type='video/mp4'
              src='https://storage.googleapis.com/www.old.additiveautomations.com/HomepageVideo.mp4'
            />
          </video>
        </div>
        <div className='google-pixel-6-pro screen'>
          <div className='image-container'>
            <video autoPlay loop muted playsInline id='mobile-preview'>
              <source
                type='video/mp4'
                src='https://storage.googleapis.com/www.old.additiveautomations.com/HomepageVideo.mp4'
              />
            </video>
            <div className='image-overlay'>
              <div className='video-player-button' onClick={this.handleVideoPlay}>
                <div className='button-container'>
                  {isIphone ? (
                    ''
                  ) : (
                    <div className='m-button-0'>
                      <div className='overlap-group'>
                        <div className='rectangle-450'></div>
                        <div className='net-shape-1 atkinsonhyperlegible-bold-white-10px'>
                          <FontAwesomeIcon icon={icon({ name: 'maximize', style: 'solid' })} /> Fullscreen
                        </div>
                      </div>
                    </div>
                  )}
                  {isIphone ? (
                    ''
                  ) : (
                    <div className='m-button-0 button-0-1'>
                      <div className='overlap-group'>
                        <div className='rectangle-450'></div>
                        <div className='net-shape-1 atkinsonhyperlegible-bold-white-10px'>
                          <FontAwesomeIcon icon={icon({ name: 'maximize', style: 'solid' })} /> Fullscreen
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <span className='overlay-big'>
                For manufacturers looking to harness the <span className='lime-green'>real</span> potential of{' '}
                <span className='lime-green'>metal AM</span>
              </span>
              <span className='overlay-small'>
                Rivelin Netshape® robots reduce the burden of{' '}
                <span className='lime-green'>post-processing</span> in near-net-shape metal manufacturing
              </span>
              <div className='hero-links'>
                <HashLink to='/meet-the-robots#top'>
                  <div className='the-robots'>
                    <div className='button-container'>
                      <Button1 button1={button11Props.button1} />
                      <Button1 button1={button12Props.button1} className={button12Props.className} />
                    </div>
                  </div>
                </HashLink>
                <HashLink to='/learn-the-tech#top'>
                  <div className='m-net-shape'>
                    <div className='button-container'>
                      <Button0 />
                      <Button0 className={button0Props.className} />
                    </div>
                  </div>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MblHomePage;

import React from 'react';
import './ApplyNow.css';
import angleRight from '../../../static/img/angle-right@1x.png';

class ApplyNow extends React.Component {
  render() {
    const { speakWithARivelinExpert } = this.props;

    return (
      <div className='mhww-apply-now'>
        <div className='mhww-overlap-group'>
          <div className='mhww-speak-with-a-rivelin-expert'>{speakWithARivelinExpert}</div>
          <div className='mhww-right-angle-arrow'>
            <img className='mhww-angle-right' src={angleRight} alt='angle-right' />
          </div>
        </div>
      </div>
    );
  }
}

export default ApplyNow;

import React from 'react';
import './NS1.scss';

class NS1 extends React.Component {
  render() {
    const { spanText1, spanText2 } = this.props;

    return (
      <div className='mltr-ns-1'>
        <div className='mltr-twoit-adapts atkinsonhyperlegible-bold-bunker-18px'>
          <span className='mltr-span0-1 atkinsonhyperlegible-normal-bunker-18px'>{spanText1}</span>
          <span className='atkinsonhyperlegible-bold-bunker-35px'>{spanText2}</span>
        </div>
      </div>
    );
  }
}

export default NS1;

import './App.scss';
import React from 'react';
import {Routes, BrowserRouter as Router, Route} from "react-router-dom";
import LrgHomePage from './components/LrgHomePage';
import LrgCareers from './components/LrgCareers';
import LrgContactUs from './components/LrgContactUs';
import LrgRequestADemo from './components/LrgRequestADemo';
import LrgPrivacyPolicy from './components/LrgPrivacyPolicy';
import LrgWhoWeAre from './components/LrgWhoWeAre';
import LrgWhyWeExist from './components/LrgWhyWeExist';
import LrgHowWeWork from './components/LrgHowWeWork';
import LrgMeetTheRobots from './components/LrgMeetTheRobots';
import LrgLearnTheTech from './components/LrgLearnTheTech';
import LrgSeeItInAction from './components/LrgSeeItInAction';
import LrgResources from './components/LrgResources';
import LrgRequestDatasheet from './components/LrgRequestDatasheet';
import LrgFAQ from './components/LrgFAQ';
import OverlIkonImage from '../src/static/img/amrc_white.png';
import gkn_2Image from '../src/static/img/GKN_2.png';
import renishaw_White from '../src/static/img/Renishaw_white.png';
import miniStoryImage from '../src/static/img/MinistryOfDefence_white.png';
import SearchIcom from '../src/static/img/magnifying-glass@1x.png';
import howweworkImage from './static/img/HeroBannerSparks3_crop.png';
import whyweexitsImage from './static/img/mask-group-3-3-1x-png@1x.png';
import overGroup from './static/img/PageBannerScan_crop.png';
import ns1CloseupImage from './static/img/ns1-closeup@1x.png';
import maskGroup4 from './static/img/mask-group-4@1x.png';
import angleRight from './static/img/angles-right-1@1x.png';
import maskGroup6 from './static/img/mask-group-6-1x-png@1x.png';
import maskgroup7 from './static/img/mask-group-7@1x.png';
import overlay1 from './static/img/tim-hufner-3wcnxptrr50-unsplash--3--1@1x.png';

class App extends React.Component {
  renderLrgHomePage() {
    return (
        <LrgHomePage
            headerLargeDesktopProps={lrgHomePageData.headerLargeDesktopProps}
            heroLargeDesktopProps={lrgHomePageData.heroLargeDesktopProps}
            primaryButton21Props={lrgHomePageData.primaryButton21Props}
            primaryButton22Props={lrgHomePageData.primaryButton22Props}
            button0Props={lrgHomePageData.button0Props}
            footerProps={lrgHomePageData.footerProps}
            oerlikon={OverlIkonImage}
            gkn_2={gkn_2Image}
            renishaw_White={renishaw_White}
            ministryofdefence_White={miniStoryImage}
        />
    );
  }
  renderLrgResources() {
    return (
        <LrgResources
            place='Home'
            anglesRight={lrgCareersData.anglesRight2}
            spanText1=''
            spanText2='Resources'
            headerLargeDesktopProps={lrgResourcesData.headerLargeDesktopProps}
            heroLargeDesktop2Props={lrgResourcesData.heroLargeDesktop2Props}
            footerProps={lrgResourcesData.footerProps}
        />
    );
  }
  render() {
    return (
        <Router>
          <Routes>
?           <Route index path="/" element={this.renderLrgHomePage()} />
            <Route path="/careers" element={<LrgCareers {...lrgCareersData} />} />
            <Route path="/resources/*" element={this.renderLrgResources()} />
            <Route path="/request-a-demo" element={<LrgRequestADemo {...lrgRequestADemoData} />} />
            <Route path="/who-we-are" element={<LrgWhoWeAre {...lrgWhoWeAreData} />} />
            <Route path="/why-we-exist" element={<LrgWhyWeExist {...lrgWhyWeExistData} />} />
            <Route path="/how-we-work" element={<LrgHowWeWork {...lrgHowWeWorkData} />} />
            <Route path="/meet-the-robots" element={<LrgMeetTheRobots {...lMeetTheRobotsData} />} />
            <Route path="/learn-the-tech" element={<LrgLearnTheTech {...lLearnTheTechData} />} />
            <Route path="/see-it-in-action" element={<LrgSeeItInAction {...lSeeItInActionData} />} />
            <Route path="/contact-us" element={<LrgContactUs {...lrgContactUsData} />} />
            <Route path="/privacy-policy" element={<LrgPrivacyPolicy {...lrgPrivacyPolicyData} />} />
            <Route path="/datasheet" element={<LrgRequestDatasheet {...lrgRequestDatasheetData} />} />
            <Route path="/faq" element={<LrgFAQ {...lrgFAQ} />} />
          </Routes>
        </Router>
    );
  }
}

export default App;
const contactUsLinkData = {
  children: 'Contact Us',
  className: 'contact-us-link',
  link: '/contact-us#top',
};

const careersLinkData = {
  children: 'Careers',
  className: 'careers-link',
  link: '/careers#top',
};

const resourcesLinkData = {
  children: 'Resources',
  className: 'resources-link',
  link: '/resources#top',
};

const faqLinkData = {
  children: 'FAQ',
  className: 'faq-link',
  link: '/faq#top',
};

const searchBarData = {
  iconSearch: { SearchIcom },
  className: '',
};

const roboticFinishingSystemsData = {
  children: 'Robotic Finishing Systems',
  className: '',
  link: '/#top',
};

const howWeWorkData = {
  heroLargeDesktop2: howweworkImage,
  children: 'How We Work',
  className: 'how-we-work',
  link: '/how-we-work#top',
};

const whoWeAreData = {
  heroLargeDesktop2: howweworkImage,
  children: 'Who We Are',
  className: 'who-we-are',
  link: '/who-we-are#top',
};

const whyWeExistData = {
  heroLargeDesktop2: whyweexitsImage,
  children: 'Why We Exist',
  className: 'why-we-exist',
  link: '/why-we-exist#top',
};

const headerLargeDesktopData = {
  className: '',
  contactUsLinkProps: contactUsLinkData,
  careersLinkProps: careersLinkData,
  resourcesLinkProps: resourcesLinkData,
  faqLinkProps: faqLinkData,
  searchBarProps: searchBarData,
  roboticFinishingSystemsProps: roboticFinishingSystemsData,
  howWeWorkProps: howWeWorkData,
  whoWeAreProps: whoWeAreData,
  whyWeExistProps: whyWeExistData,
};

const heroLargeDesktopHomeData = {
  heroLargeDesktop: '/img/image-1x-png-1x-png@1x.png',
  spanText1: 'AUTOMATED, RELIABLE & REPEATABLE ',
  spanText2: 'POST PROCESSING SOLUTIONS',
  spanText3: 'FOR MANUFACTURERS LOOKING TO HARNESS THE REAL POTENTIAL OF METAL AM',
  spanText4:
    "The automation of common metal AM post processing tasks — such as support removal & surface finishing – protects workers' health, tackles skill shortages and reduces costs.",
  rivelinSNetshapeR:
    'RIVELIN NETSHAPE® ROBOTS REDUCE THE BURDEN OF POST PROCESSING IN NEAR-NET SHAPE METAL MANUFACTURING',
};

const privacyPolicyLinkData = {
  className: 'privacy-policy-link-1',
};

const youtubeData = {
  className: '',
};

const linkedinData = {
  className: '',
};

const githubData = {
  className: '',
};

const footerData = {
  privacyPolicyLinkProps: privacyPolicyLinkData,
  youtubeProps: youtubeData,
  linkedinProps: linkedinData,
  githubProps: githubData,
};

const primaryButton22Data = {
  primaryButton: './static/img/base@1x.png',
  openPositions: 'THE ROBOTS',
};

const primaryButton23Data = {
  primaryButton: '/img/base-1-1x-png-1x-png@1x.png',
  openPositions: 'THE ROBOTS',
  className: 'button-1-1',
};

const button02Data = {
  className: 'button-0-1',
};

const lrgHomePageData = {
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktopProps: heroLargeDesktopHomeData,
  primaryButton21Props: primaryButton22Data,
  primaryButton22Props: primaryButton23Data,
  button0Props: button02Data,
  footerProps: footerData,
};

const heroLargeDesktopCareersData = {
  heroLargeDesktop2: howWeWorkData,
  title: 'Careers',
};

const readTheirStoryData = {
  className: 'read-their-story-1',
};

const storyData = {
  image1: '',
  spanText1: 'David Mason',
  spanText2: (
    <React.Fragment>
      {' '}
      <br />
      Climber, labradoodle parent and{' '}
    </React.Fragment>
  ),
  spanText3: 'systems engineer',
  image2: 'Image2',
  spanText4: 'David Alatorre',
  spanText5: (
    <React.Fragment>
      {' '}
      <br />
      Scientist, roboticist, skydiver, father and{' '}
    </React.Fragment>
  ),
  spanText6: 'chief technology officer',
  image3: 'Image3',
  spanText7: '',
  spanText8: 'Tom Noble',
  spanText9: (
    <React.Fragment>
      {' '}
      <br />
      Computer scientist, robotics enthusiast and{' '}
    </React.Fragment>
  ),
  spanText10: 'Software Engineer',
  readTheirStory1Props: readTheirStoryData,
  readTheirStory2Props: readTheirStoryData,
  dm_bio: [
    'My engineering journey started in Sheffield where I completed a 5 year masters course in mechanical engineering and inevitably fell in love with the city and the Peak District.',
    'It was at University that my interest in additive manufacturing began through learning about the technologies and their uses. During my year in industry I used the extra income to purchase a Prusa Mendel kit, think threaded rods for the structural frame!, which quickly led to me developing my own bespoke FDM printer using the best (affordable) technology at the time to try overcome the issues I had experienced with the Prusa.',
    'After university I secured a job in Derby at Rolls-Royce on their graduate + leadership programmes. During my 6 years there I worked in a wide variety of roles from safety critical software engineering to a development engineer on the Ultrafan project. I always kept coming back to the additive manufacturing related positions though. Most notably I was the lead design engineer for Rolls-Royce’s first additively manufactured civil large engine bearing chamber on the Ultrafan engine. I was able to use the evidence from my time at Rolls-Royce to gain chartered engineer status. During my time in Derby my love for climbing developed and I soon found myself spending a lot of time out on the peak district edges and in the old quarries.',
    'Then in the middle of a pandemic my Fiancée was given the opportunity to continue her doctor training in Sheffield and we had our chance to return to the city we loved and to be even closer to our favourite climbing destinations. This is when my job search revealed Rivelin Robotics. Robots combined with working in the additive manufacturing field and right on the doorstep of my new home made it the perfect opportunity for me. A stroke of luck also meant that a contact I had made during my year in industry tagged Robert Bush in my Linked in post asking about opportunities in the Sheffield region. I completed the various application stages and must have made a good impression as I was offered the job!',
    'Since starting at the company it has been a whirlwind experience with things moving at a rapid pace that Is only possible with the startup mentality and agile nature. In the last 9 months I’ve designed and built our first in-house automation cell, designed and tested bespoke end effectors for support removal. I’ve learnt a huge range of new skills and every day I encounter new challenges which makes the job incredibly rewarding and exciting! Importantly I’ve also become part of a small but great team, with common interests in the outdoors and trying to be environmentally friendly.',
    'And lastly remember that little FDM printer I designed all those years ago; well it also found a full time job at Rivelin Robotics. It now spends it’s days producing custom end effector mounts and bespoke fixtures using recycled filament.',
  ],
  da_bio: [
    'I am Chief Technology Officer at Rivelin Robotics. Raised and educated in Mexico, the USA and the UK, I am a mechanical engineer with a PhD in control systems for low-stiffness grinding robots. I have over seven years of experience in the field of robotics and a lifetime of obsession with all things engineering.',
    'For my 9th birthday I got a Lego Mindstorms robotics kit and discovered the beauty of mechanisms and the magic of control. I found where I belonged, and never again questioned what classes to take in school, or what degrees to choose for university or what PhD to apply for. I have known I would dedicate my life to robotics from the day my dad showed me how a big gear makes a small gear turn faster.',
    'I always enjoyed nature and outdoor sports like mountain biking, climbing and mountaineering. At the University of Nottingham I joined the skydiving club and discovered a beautiful and obscure sport that catered to my geeky appreciation of aviation as well as to my craving for good views and fresh air. When it was time to choose a third year design and make project, I was fortunate enough to find a team of engineering parachutists and a willing supervisor to make the world’s first skydiving robot.',
    'I then completed my PhD at the Rolls-Royce UTC for On-Wing Technology, a research group dedicated to advancing the use of robotics for in-site repair in challenging environments. During my studies and for a further 3 years as Research Associate, I developed a number of continuum robots equipped with grinding tools, inspection cameras and ablation lasers, and had my first experience of taking a product from concept to industry tests.',
    'I first joined Rivelin Robotics to advise the team in the area of force control for grinding robots and to deploy a bespoke control algorithm. I quickly realised that the team and the company’s values were a perfect fit for me, and found myself as excited about the company’s mission as I have been for any project before. When I was offered to stay full-time and join the executive team I jumped at the opportunity. In the time since then, I have seen our product develop in incredibly exciting ways and I have seen our tech team grow and adapt to the barrage of challenges that come with new technology. I am immensely proud to be part of the team here at Rivelin Robotics.',
  ],
  sa_bio: [
    'My name is Tom Noble, and I’m currently a Senior Software Engineer at Rivelin Robotics. Here, I take charge of crafting the software that powers our cutting-edge robotic post-processing systems. My journey in robotics began early in life as a student and grew into a passion.',
    'This passion naturally guided my academic pursuits, leading me to major in computer science. Post-graduation, while I took roles as a software engineer across several companies, I constantly felt a piece missing from my professional puzzle. My yearning was to channel my skills into something genuinely transformative and, importantly, something I deeply resonated with.',
    'Rivelin Robotics presented me with that exact opportunity. Drawn by the companies visionary approach to reshaping the metal additive manufacturing industry through robotics, I knew I had found my place. Two years into this journey, I have contributed immensely to the team. From enhancing the performance of our robotic systems with innovative software algorithms to integrating features that amplify user experience, my mark is evident.',
    'However, my role isn’t just defined by my technical prowess. I see myself as an ambassador for robotics, relentlessly pursuing advancements and improvements in the field. More so, I pride myself on being a collaborative team member, always available to lend a helping hand.',
    'Being a part of Rivelin Robotics isn’t just a job for me, it’s a realization of my passion, and I look forward to the innovations and transformations we will spearhead in the future.',
  ],
};

const applyNowRole1Data = {
  applyNow: 'APPLY NOW',
  link: 'https://erp.rivelinrobotics.com/jobs/chief-operations-officer-12',
};

const applyNowRole2Data = {
  applyNow: 'APPLY NOW',
  link: 'https://erp.rivelinrobotics.com/jobs/devops-infrastructure-engineer-13',
};

const applyNowRole3Data = {
  applyNow: 'APPLY NOW',
  link: 'https://erp.rivelinrobotics.com/jobs/detail/senior-applications-engineer-5',
};

const applyNowRole4Data = {
  applyNow: 'APPLY NOW',
  link: 'https://erp.rivelinrobotics.com/jobs/adminops-specialist-14',
};

const applyNowRole5Data = {
  applyNow: 'APPLY NOW',
  link: 'https://erp.rivelinrobotics.com/jobs/devops-infrastructure-engineer-13',
};

const applyNowRole6Data = {
  applyNow: 'APPLY NOW',
  link: 'https://erp.rivelinrobotics.com/jobs/detail/robotics-software-engineer-6',
};

const button022Data = {
  button0: '/img/path-153-1@1x.png',
  ourTeam: 'OUR TEAM',
};

const button031Data = {
  button0: '/img/path-153-1-1x-png@1x.png',
};

const primaryButton24Data = {
  primaryButton: '/img/base-1@1x.png',
  openPositions: 'OPEN POSITIONS',
  className: 'primary-button-4',
};

const primaryButton25Data = {
  primaryButton: '/img/base-3@1x.png',
  openPositions: 'OPEN POSITIONS',
  className: 'primary-button-8',
};

const button032Data = {
  className: 'button-0-4',
};

const lrgCareersData = {
  place: 'Home',
  anglesRight1: howweworkImage,
  whoWeAreLink: 'Who We Are',
  anglesRight2: angleRight,
  careersPlaceholder: 'Careers',
  spanText1: (
    <React.Fragment>
      Our people is what makes us special.
      <br />
    </React.Fragment>
  ),
  spanText2: (
    <React.Fragment>
      <br />
    </React.Fragment>
  ),
  spanText3:
    'Building technology that tells robots what to do is a challenging team game. If you want to join a supportive team that will help you grow as a human being and improve your career, click on one of the openings below, or reach out with a general enquiry at ',
  spanText4: 'info@rivelinrobotics.com',
  spanText5: '.',
  splitBanner: '/img/ewirabracket-1x-png-1x-png@1x.png',
  overlapGroup: overGroup,
  spanText6: (
    <React.Fragment>
      JOIN OUR TEAM
      <br />
    </React.Fragment>
  ),
  spanText7: (
    <React.Fragment>
      Solve industry&#x27;s greatest challenges with intelligent robotics, ML and data science.
      <br />
    </React.Fragment>
  ),
  spanText8: '',
  spanText9: (
    <React.Fragment>
      OPERATIONS
      <br />
    </React.Fragment>
  ),
  spanText10: (
    <React.Fragment>
      Chief Operating Officer
      <br />
    </React.Fragment>
  ),
  spanText11:
    'Lead our team in a pivotal expansion, executing key strategies at our cutting-edge robotics facility.',
  spanText12: (
    <React.Fragment>
      OPERATIONS
      <br />
    </React.Fragment>
  ),
  spanText13: (
    <React.Fragment>
      DevOps Infrastructure Engineer
      <br />
    </React.Fragment>
  ),
  spanText14:
    'Oversee cybersecurity, integrations, authentication, and cloud infrastructure for Rivelin Robotics\' growth.',
  spanText15: (
    <React.Fragment>
      OPERATIONS
      <br />
    </React.Fragment>
  ),
  spanText16: (
    <React.Fragment>
      AdminOps Specialist
      <br />
    </React.Fragment>
  ),
  spanText17:
    'Architect workflows and orchestrate processes while supporting every facet of Rivelin Robotics\' expanding operations.',
  spanText18: (
    <React.Fragment>
      PRODUCT
      <br />
    </React.Fragment>
  ),
  spanText19: (
    <React.Fragment>
      Robotic Applications Engineer
      <br />
    </React.Fragment>
  ),
  spanText20:
    'Our robotic applications engineers work with customers to turn ideas into products using digital tools',
  spanText21: (
    <React.Fragment>
      PRODUCT
      <br />
    </React.Fragment>
  ),
  spanText22: (
    <React.Fragment>
      Senior Applications Engineer
      <br />
    </React.Fragment>
  ),
  spanText23:
    'As a senior applications engineer, you will lead internal and customer projects and take ownership of our tool control subsystems',
  spanText24: (
    <React.Fragment>
      SOFTWARE
      <br />
    </React.Fragment>
  ),
  spanText25: (
    <React.Fragment>
      Robotics Software Engineer
      <br />
    </React.Fragment>
  ),
  spanText26:
    'Our Robotics software engineers build and maintain NetShape™, our flagship platform for robot teaching, perception and control',
  line2: '/img/line-2@1x.png',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopCareersData,
  button02Props: button022Data,
  button031Props: button032Data,
  button03Props: button031Data,
  primaryButton21Props: primaryButton24Data,
  primaryButton22Props: primaryButton25Data,
  storyProps: storyData,
  applyNowRole1Props: applyNowRole1Data,
  applyNowRole2Props: applyNowRole2Data,
  applyNowRole3Props: applyNowRole3Data,
  applyNowRole4Props: applyNowRole4Data,
  applyNowRole5Props: applyNowRole5Data,
  applyNowRole6Props: applyNowRole6Data,
  footerProps: footerData,
};

const heroLargeDesktopResourcesData = {
  heroLargeDesktop2: howweworkImage,
  title: 'Resources',
  className: 'hero-large-desktop2-8',
};

const lrgResourcesData = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'Resources',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopResourcesData,
  footerProps: footerData,
};

const lrgFAQ = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'FAQ',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopResourcesData,
  footerProps: footerData,
};

const heroLargeDesktopWhoWeAreData = {
  heroLargeDesktop2: howweworkImage,
  title: 'Who We Are',
  className: 'hero-large-desktop2-2',
};

const button033Data = {
  button0: '../src/static/img/path-153-1-1x-png@1x.png',
};

const primaryButton26Data = {
  className: 'button-3',
};

const learnMoreData = {
  applyNow: 'LEARN MORE',
  className: 'apply-now-2',
  link: '/why-we-exist#top',
};

const lrgWhoWeAreData = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'Who We Are',
  factoriesOfTheFutureToday: 'Factories of the future, today.',
  weAreRivelinWeA: (
    <React.Fragment>
      We are Rivelin.
      <br />
      We are here to evolve industry.
      <br />
      Evolve to a world driven by digitalisation and the need for sustainable manufacturing.
      <br />
      Today we&#x27;re focused on metal additive manufacturing support removal and finishing but that&#x27;s
      only the beginning.
    </React.Fragment>
  ),
  past: 'Past',
  rivelinRoboticsWas:
    'Rivelin Robotics was founded by Robert Bush while working as metals production engineer at 3T-AM (BEAMIT) after battling with the challenge of getting the first metal AM part through PPAP (Production Part Approval Process).',
  present: 'Present',
  ourRoboticFinishin:
    'Our robotic finishing systems are empowering engineers to automate support removal and polishing to meet the standards of mission-critical production lines.',
  future: 'Future',
  autonomousPostProc:
    'Autonomous post processing for metal AM production parts, spare parts and the repair of parts so local manufacturers everywhere can remake the way we make things and accomplish a sustainable manufacturing industry within our lifetime.',
  overlapGroup1: './static/img/tim-hufner-3wcnxptrr50-unsplash--3-@1x.png',
  robertbush_Nobkg1: './static/img/robertbush-nobkg1-1@1x.png',
  iconLinkedin1: './static/img/linkedin-1@1x.png',
  spanText3: (
    <React.Fragment>
      Robert Bush
      <br />
    </React.Fragment>
  ),
  spanText4: 'Chief Executive Officer',
  overlapGroup2: overlay1,
  dalatorre_Nobkg: './static/img/dalatorre-nobkg@1x.png',
  iconLinkedin2: './static/img/linkedin-1@1x.png',
  spanText5: (
    <React.Fragment>
      David Alatorre
      <br />
    </React.Fragment>
  ),
  spanText6: 'Chief Technical Officer',
  timHufner3Wcnxptrr50Unsplash3: './static/img/tim-hufner-3wcnxptrr50-unsplash--3--2@1x.png',
  davidmason_Nobkg1: './static/img/davidmason-nobkg1@1x.png',
  iconLinkedin3: './static/img/linkedin-1@1x.png',
  spanText7: (
    <React.Fragment>
      David Mason
      <br />
    </React.Fragment>
  ),
  spanText8: 'Chief Product Officer',
  overlapGroup4: './static/img/tim-hufner-3wcnxptrr50-unsplash--3--3@1x.png',
  ianhalliday_Nobkg1: './static/img/ianhalliday-nobkg1@1x.png',
  iconLinkedin4: './static/img/linkedin-1@1x.png',
  spanText9: (
    <React.Fragment>
      Ian Halliday
      <br />
    </React.Fragment>
  ),
  spanText10: 'Investor & Advisor',
  rivelintortuga: '/img/rivelintortuga@1x.png',
  spanText11: (
    <React.Fragment>
      REIMAGINE A WORLD WITHOUT WASTE
      <br />
    </React.Fragment>
  ),
  spanText12: 'We exist to drive the mass uptake of metal AM across industry.',
  inASystemWhereCo:
    'In a system where companies are being held accountable for their carbon footprint, the linear story is evolving to a circular one. Our people see metal AM as a major contributor to a sustainable future, and which will have lasting benefits for our environment. With NetShape Robots we introduce our first breakthrough to drive the uptake of metal AM.',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopWhoWeAreData,
  button031Props: button032Data,
  button032Props: button033Data,
  primaryButton21Props: primaryButton26Data,
  applyNowProps: learnMoreData,
  footerProps: footerData,
};

const heroLargeDesktopWhyWeExistData = {
  heroLargeDesktop2: whyweexitsImage,
  title: 'Why We Exist',
  className: 'hero-large-desktop2-3',
};

const problem1Data = {
  problem: 'Problem',
  additiveManufacturi:
    'Additive manufacturing (AM) requires support structures to prevent sagging and deformation of overhanging geometries. Support removal is the most labour-intensive part of AM post-processing, and when including surface witness removal accounts for upwards of 25% of total part cost. Support removal is a skilled, sometimes hazardous, and always unpleasant manual task requiring years to perfect and leads to variability between parts and from person to person, and introduces inaccuracies that can compromise end-use functionality and design intent.',
};

const problem2Data = {
  problem: 'Solution',
  additiveManufacturi:
    'The solution is Rivelin’s NetShape Robots with the company’s NetShape control and perception software. This drives a combination of pneumatic and electro-mechanical tools that can remove supports and surface witnesses in a wide range of metallic materials. Rivelin’s Netshape Robots adapt to fixture and part variability using a grinding-specific force control algorithm and a “Scan & Plan” system. Our automated calibration system enables engineers and technicians to quickly set-up and start production runs using their existing levels of knowledge and intuition.',
  className: 'solution-1',
};

const problem3Data = {
  problem: 'Problem',
  additiveManufacturi:
    'Additive manufactured components often exhibit high surface roughness and visible layers. Localised polishing is typically done by hand or in a rigid machine tool, both methods being expensive and introducing quality issues due to the surface variability often found in AM parts. Manual polishing varies over time and from worker to worker leading to unacceptable inconsistencies in production.',
  className: 'problem-2',
};

const problem4Data = {
  problem: 'Solution',
  additiveManufacturi:
    "Rivelin's metalworking tools are used to finish, fettle, linish, and polish a wide range of metallic materials. NetShape Robots adapt to surface feature variability using a 3D vision system and dynamic planning capability. Our force control system reacts to machining forces and tracks a desired polishing pressure to achieve a consistent surface finish, and our non-contact tool wear monitoring maintains a stable process.",
  className: 'solution-7',
};

const problem5Data = {
  problem: 'Problem',
  additiveManufacturi:
    'Components often leave additive manufacturing machines attached to a rigid metal platform. Wire EDM is frequently used to de-platform parts, but can be unreliable around metal powder due to shorting and wire breakages. Conventional tools such as bandsaws are susceptible to drift without any form of positional feedback and may cause AM parts to require rework or to be scrapped. EDMs or bandsaws are expensive and large-footprint machines that require skilled operation.',
  className: 'problem-4',
};

const problem6Data = {
  problem: 'Solution',
  additiveManufacturi:
    "Rivelin's end-of-arm tools can remove plates and platforms in a wide range of materials, while adapting to warping and variability using a 3D vision system and dynamic planning capability. Our grinding-specific force control system reacts and stabilises non-linear machining forces. Our autonomous programming empowers engineers and technicians with no prior robotics experience to quickly create robot trajectories and tool operations.",
  className: 'solution-4',
};

const scanPlan3Data = {
  className: 'scan-plan-4',
};

const problem7Data = {
  problem: 'Problem',
  additiveManufacturi:
    'Unsintered and semi-sintered powder can be found within holes and internal channels of additive manufactured components. If left, residual powder can have serious consequences and can compromise part functionality. The manual work to remove powder is hazardous and often results vary from part to part and from site to site. This variability presents a complex risk in product development that can be costly to manage.',
  className: 'problem-6',
};

const problem8Data = {
  problem: 'Solution',
  additiveManufacturi:
    "Rivelin uses a combination of pneumatic and electro-mechanical tools to release caked, semi-sintered, and un-sintered powder. Rivelin's NetShape Robots adapt to variability in the condition and location of holes, corners, and channels using a 3D vision system and dynamic planning capability. Our automated calibration system enables engineers and technicians to quickly set-up and start production runs using their existing levels of knowledge and intuition.",
  className: 'solution',
};

const automaticSystemCalibration4Data = {
  iconAutomaticCalibration: './static/img/icon-automatic-calibration-1@1x.png',
};

const atAGlance31Data = {
  className: 'at-a-glance-4',
  automaticSystemCalibrationProps: automaticSystemCalibration4Data,
};

const automaticSystemCalibration3Data = {
  iconAutomaticCalibration: './static/img/icon-automatic-calibration-1@1x.png',
};

const lrgWhyWeExistData = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'Why We Exist',
  weExistToDriveTh: 'We exist to drive the mass uptake of metal AM across industry.',
  splitBanner: '/img/ewirabracket-1x-png-1x-png@1x.png',
  overlapGroup: overGroup,
  spanText3: (
    <React.Fragment>
      SOLUTIONS
      <br />
    </React.Fragment>
  ),
  spanText4: 'Reliable metal AM production lines finish with reliable & fast NetShape Robots',
  spanText5: '',
  spanText6: (
    <React.Fragment>
      METAL AM: POWDER BED, WIRE ARC, BINDER JET
      <br />
    </React.Fragment>
  ),
  spanText7: 'Support Removal',
  line2: '/img/line-2@1x.png',
  spanText8: (
    <React.Fragment>
      METAL AM: POWDER BED, WIRE ARC, BINDER JET
      <br />
    </React.Fragment>
  ),
  spanText9: 'Finishing & Polishing',
  line3: '/img/line-2@1x.png',
  spanText10: (
    <React.Fragment>
      METAL AM: POWDER BED, WIRE ARC, BINDER JET
      <br />
    </React.Fragment>
  ),
  spanText11: 'Platform Removal',
  atAGlance: 'At A Glance',
  iconAutonomousProgramming: './static/img/icon-autonomous-programming@1x.png',
  autonomousProgramming: (
    <React.Fragment>
      Autonomous <br />
      Programming
    </React.Fragment>
  ),
  line4: '/img/line-2@1x.png',
  spanText12: (
    <React.Fragment>
      METAL AM: BINDER JET, POWDER BED <br />
    </React.Fragment>
  ),
  spanText13: 'Depowdering',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopWhyWeExistData,
  problem1Props: problem1Data,
  problem2Props: problem2Data,
  problem3Props: problem3Data,
  problem4Props: problem4Data,
  atAGlance1Props: atAGlance31Data,
  problem5Props: problem5Data,
  problem6Props: problem6Data,
  scanPlanProps: scanPlan3Data,
  problem7Props: problem7Data,
  problem8Props: problem8Data,
  atAGlance2Props: atAGlance31Data,
  automaticSystemCalibrationProps: automaticSystemCalibration3Data,
  atAGlance3Props: atAGlance31Data,
  footerProps: footerData,
};

const heroLargeDesktopHowWeWorkData = {
  heroLargeDesktop2: howweworkImage,
  title: 'How We Work',
  className: 'hero-large-desktop2-4',
};

const applyNow1Data = {
  applyNow: 'SPEAK WITH A RIVELIN EXPERT',
  link: '/contact-us#top',
  className: 'how-we-work-link',
};

const lrgHowWeWorkData = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'How We Work',
  ns1Closeup: ns1CloseupImage,
  spanText3: (
    <React.Fragment>
      RISK-FREE DEPLOYMENT
      <br />
    </React.Fragment>
  ),
  spanText4: 'Maximise your uptime',
  rivelinSCommitted: (
    <React.Fragment>
      Rivelin&#x27;s committed to supporting you throughout the production journey; NPI (New Product
      Introduction), Pre-Production Trials, FAT (Factory Acceptance Tests), SAT (Site Acceptance Tests),
      In-Production and Post-Production Decommissioning. <br />
      <br />
      The goal is to maximise value for our partners, and to achieve this Rivelin takes care of the software,
      hardware, service and preventative maintenance. We work with you every step of the way, from our factory
      floor to yours.
    </React.Fragment>
  ),
  spanText5: (
    <React.Fragment>
      PRICING MODEL
      <br />
    </React.Fragment>
  ),
  spanText6: 'Your new 12-month labour contract',
  maskGroup4: maskGroup4,
  areYouComfortable: (
    <React.Fragment>
      Are you comfortable with CapEx? Or would you prefer a more flexible, revenue approach?
      <br />
      <br />
      Think of the Rivelin solution as an OpEx. Think of it as your new 12-month labour contract.
    </React.Fragment>
  ),
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopHowWeWorkData,
  applyNowProps: applyNow1Data,
  footerProps: footerData,
};

const heroLargeDesktopMeetTheRobotsData = {
  heroLargeDesktop2: howweworkImage,
  title: 'Meet The Robots',
  className: 'hero-large-desktop2-5',
};

const button023Data = {
  className: 'button-0-4-1',
};

const lMeetTheRobotsData = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'Meet The Robots',
  maskGroup5: '',
  prototyping: 'EDUCATION',
  spanText3: (
    <React.Fragment>
      NS0
      <br />
    </React.Fragment>
  ),
  spanText4: (
    <React.Fragment>
      <br />
      The NS0 is designed primarily for educational and academic environments,
      offering an affordable platform to delve into and experience cutting-edge
      digital manufacturing software. It comes equipped with the top-tier
      Netshape control, advanced sensing and instructional capabilities.
      <br /><br />
      Ideal for tasks like support removal, cutting, grinding, finishing, and
      polishing demonstrations and education, the NS0 seamlessly integrates with
      prevalent CAD formats and can manage components weighing up to 150kg
    </React.Fragment>
  ),
  spanText6: '.',
  title: 'NetShape Robots, your new team of finishers.',
  maskGroup6: maskGroup6,
  spanText7: (
    <React.Fragment>
      NS1
      <br />
    </React.Fragment>
  ),
  spanText8: (
    <React.Fragment>
      <br />
      The NS1 is tailored for those transitioning into full-scale production, adeptly handling tasks like support removal, finishing, polishing, and blasting. With its dual finishing zones, it promotes efficiency by allowing simultaneous finishing on one side while loading and unloading on the other. Boasting a compatibility with parts weighing up to 600kg and dimensions of 800mm x 650mm x 600mm, the NS1 is versatile and material-agnostic, ensuring a wide range of applications.{' '}
    </React.Fragment>
  ),
  factoryProduction: 'PRODUCTION',
  maskGroup7: maskgroup7,
  spanText11: (
    <React.Fragment>
      NS2
      <br />
    </React.Fragment>
  ),
  spanText12: (
    <React.Fragment>
      <br />
      The NS2 elevates the capabilities of its predecessor, the NS1, by seamlessly integrating 8 + 8 axis parallel processing. This advanced feature is achieved by harnessing the power of two robots operating simultaneously. Whether it's inspection, handling, pick & place actions, fixturing, cleaning, or concurrent machining, the NS2 ensures unparalleled efficiency and precision. It's the optimal solution for those seeking to maximize productivity and finesse in their operations.
      </React.Fragment>
  ),
  inFieldProduction: 'DUAL PRODUCTION',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopMeetTheRobotsData,
  button021Props: button023Data,
  footerProps: footerData,
};

const heroLargeDesktopLearnTheTechnologyData = {
  heroLargeDesktop2: howweworkImage,
  title: 'Learn The Technology',
  className: 'hero-large-desktop2-6',
};

const lLearnTheTechData = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'Learn The Technology',
  title: 'Truly Autonomous Finishing',
  maskGroup5: './static/img/mask-group-5-1@1x.png',
  spanText3: (
    <React.Fragment>
      It Sees.
      <br />
    </React.Fragment>
  ),
  spanText4: (
    <React.Fragment>
      <br />
      Rivelin&#x27;s system scans and creates a 3D model of each part. <br />
      <br />
      Proprietary AI snaps the scanned 3D model to the net shape CAD model.
    </React.Fragment>
  ),
  one: 'ONE',
  maskGroup6: maskGroup6,
  spanText5: (
    <React.Fragment>
      It Adapts.
      <br />
    </React.Fragment>
  ),
  spanText6: (
    <React.Fragment>
      <br />
      Rivelin NetShape<span className='atkinsonhyperlegible-super'>®</span> software recognises variability
      and generates intelligent finishing paths to compensate. <br />
      <br />
      NetShape<span className='atkinsonhyperlegible-super'>®</span> adapts to imprecise parts, fixturing
      variation and slipping in the chuck
    </React.Fragment>
  ),
  two: 'TWO',
  maskGroup7: maskgroup7,
  spanText7: (
    <React.Fragment>
      It Finishes.
      <br />
    </React.Fragment>
  ),
  spanText8: (
    <React.Fragment>
      <br />
      The Rivelin System understands finishing and continuously builds a map of surface topologies, high/low
      spots and tool wear.
      <br />
      <br />
      Rivelin uses standard finishing tools like belt sanders, micro grinders, ultrasonic polishers, nippers
      and pneumatic hammers - only manipulated by a robot.
    </React.Fragment>
  ),
  three: 'THREE',
  headerLDesktopAltProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopLearnTheTechnologyData,
  footerProps: footerData,
};

const heroLargeDesktopSeeItInActionData = {
  heroLargeDesktop2: howweworkImage,
  title: 'See It In Action',
  className: 'hero-large-desktop-see-it-in-action',
};

const supportRemovalEBM1Data = {
  aerospaceEwiraBracket2Min_1: './static/img/aerospace---ewira-bracket--2-min--1@1x.png',
  supportRemovalEbm: 'Support removal | EB-PBF',
  autonomousFinishing:
    'Autonomous finishing in action on this bracket. Flexible fixturing removes the need for perfect part placement.',
};

const supportRemovalEBM2Data = {
  aerospaceEwiraBracket2Min_1: '/img/medical---cranial-plate--2-min--1@1x.png',
  supportRemovalEbm: 'Finishing | Titanium',
  autonomousFinishing:
    "Machining hard alloys demands high machine stiffness. Instead Rivelin's NetShape Robots finish like you or me would, and that allows us to use nimble, agile and compact industrial robots.",
  className: 'finishing-titanium',
};

const lSeeItInActionData = {
  place: 'Home',
  anglesRight: angleRight,
  spanText1: '',
  spanText2: 'See It In Action',
  hidraulicManifoldSupportRemoval_1: './static/img/hidraulic-manifold---support-removal-1@1x.png',
  supportRemovalLPbf: 'Support removal | L-PBF',
  netshapeRobotsCan: "NetShape Robots can handle the worst so you don't have to.",
  defenceSatelliteBracket3Min_1: './static/img/defence---satellite-bracket--3-min--1@1x.png',
  finishingLbpf: 'Finishing | L-PBF',
  complexAndFreeFor:
    'Complex and free-form geometries require time-consuming and skill-intensive programming. That makes it uneconomical compared with manual finishing. We have changed that.',
  headerLDesktopAltProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopSeeItInActionData,
  supportRemovalEBM1Props: supportRemovalEBM1Data,
  supportRemovalEBM2Props: supportRemovalEBM2Data,
  footerProps: footerData,
};

const heroLargeDesktopRequestADemoData = {
  children: 'Request a Demo',
};

const heroLargeDesktopRequestDatasheetData = {
  children: 'Download datasheet (via email)',
};

const emailData = {
  spanText1: 'Email',
};

const companyNameData = {
  spanText1: 'Company name',
};

const buttonHover4Data = {
  className: 'button-2',
};

const lrgRequestADemoData = {
  firstName: 'First name',
  inputType1: 'text',
  inputPlaceholder1: ' ',
  lastName: 'Last name',
  inputType2: 'text',
  inputPlaceholder2: ' ',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop3Props: heroLargeDesktopRequestADemoData,
  email21Props: emailData,
  email22Props: companyNameData,
  buttonHoverProps: buttonHover4Data,
  footerProps: footerData,
};

const lrgRequestDatasheetData = {
  firstName: 'First name',
  inputType1: 'text',
  inputPlaceholder1: ' ',
  lastName: 'Last name',
  inputType2: 'text',
  inputPlaceholder2: ' ',
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop3Props: heroLargeDesktopRequestDatasheetData,
  email21Props: emailData,
  email22Props: companyNameData,
  buttonHoverProps: buttonHover4Data,
  footerProps: footerData,
};

const heroLargeDesktopContactUsData = {
  heroLargeDesktop2: howweworkImage,
  title: 'Contact Us',
  className: 'hero-large-desktop2-1',
};

const buttonHover2Data = {
  className: 'button',
};

const lrgContactUsData = {
  firstName: 'First name',
  inputType1: 'text',
  inputPlaceholder1: ' ',
  lastName: 'Last name',
  inputType2: 'text',
  inputPlaceholder2: ' ',
  spanText1: 'Message',
  spanText2: '*',
  inputType3: 'text',
  inputPlaceholder3: ' ',
  spanText3: (
    <React.Fragment>
      Rivelin Technology Centre
      <br />
    </React.Fragment>
  ),
  spanText4: (
    <React.Fragment>
      Unit 1, Neepsend Triangle
      <br />
      1 Burton Rd
      <br />
      Sheffield, S3 8BW
      <br />
      United Kingdom
    </React.Fragment>
  ),
  headerLargeDesktopProps: headerLargeDesktopData,
  heroLargeDesktop2Props: heroLargeDesktopContactUsData,
  email1Props: emailData,
  email2Props: companyNameData,
  buttonHoverProps: buttonHover2Data,
  footerProps: footerData,
};

const lrgPrivacyPolicyData = {
  spanText1: (
    <React.Fragment>
      Website Privacy Policy
      <br />
    </React.Fragment>
  ),
  spanText2: (
    <React.Fragment>
      1 INTRODUCTION
      <br />
    </React.Fragment>
  ),
  spanText4: '2	LEGAL BASIS FOR DATA COLLECTION',
  spanText6: '• “Consent”',
  spanText7: (
    <React.Fragment>
      : Certain situations allow us to collect your Personal Data, such as when you tick a box that confirms
      you are happy to receive email newsletters from us, or ‘opt in’ to a service.
      <br />
    </React.Fragment>
  ),
  spanText8: '• “Contractual Obligations”',
  spanText9: (
    <React.Fragment>
      : We may require certain information from you in order to fulfil our contractual obligations and provide
      you with the promised service.
      <br />
    </React.Fragment>
  ),
  spanText10: '• “Legal Compliance”',
  spanText11: (
    <React.Fragment>
      : We’re required by law to collect and process certain types of data, such as fraudulent activity or
      other illegal actions.
      <br />
    </React.Fragment>
  ),
  spanText12: '• “Legitimate Interest”',
  spanText13: (
    <React.Fragment>
      : We might need to collect certain information from you to be able to meet our legitimate interests -
      this covers aspects that can be reasonably expected as part of running our business, that will not have
      a material impact on your rights, freedom or interests. Examples could be your address, so that we know
      where to deliver something to, or your name, so that we have a record of who to contact moving forwards.
      <br />
      <br />
    </React.Fragment>
  ),
  spanText14: '3	HOW WE USE YOUR PERSONAL DATA',
  spanText16: '4	YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US',
  spanText18: '5	YOUR DATA AND THIRD PARTIES',
  spanText20: (
    <React.Fragment>
      <br />6 HOW LONG WILL WE RETAIN YOUR DATA FOR?
      <br />
    </React.Fragment>
  ),
  spanText21: (
    <React.Fragment>
      We will only retain your Personal Data for as long as reasonably necessary to fulfil the purposes we
      collected it for. We may retain your Personal Data for a longer period than usual in the event of a
      complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship
      with you.
      <br />
      <br />
    </React.Fragment>
  ),
  spanText22: (
    <React.Fragment>
      7 AGE LIMIT FOR OUR USERS
      <br />
    </React.Fragment>
  ),
  spanText23: (
    <React.Fragment>
      <br />
      You must not use Rivelin Robotics Limited unless you are aged 16 or older. If you are under 16 and you
      access Rivelin Robotics Limited by lying about your age, you must immediately stop using Rivelin
      Robotics Limited.
      <br />
      This website is not intended for children and we do not knowingly collect data relating to children.
      <br />
      <br />
    </React.Fragment>
  ),
  spanText24: (
    <React.Fragment>
      8 INTERNATIONAL TRANSFER OF DATA
      <br />
    </React.Fragment>
  ),
  spanText25: (
    <React.Fragment>
      <br />
      Your information may be stored and processed in the US or other countries or jurisdictions outside the
      US where Rivelin Robotics Limited has facilities. We are currently storing data in the EU and so, by
      using Rivelin Robotics Limited, you are permitting and consenting to the transfer of information,
      including Personal Data, outside of the US.
      <br />
      <br />
    </React.Fragment>
  ),
  spanText26: (
    <React.Fragment>
      9 NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY
      <br />
    </React.Fragment>
  ),
  spanText27: (
    <React.Fragment>
      <br />
      We keep our Privacy Policy under review and will place any updates on this webpage. This version is
      dated 1 August 2022. By using Rivelin Robotics Limited, you consent to the collection and use of data by
      us as set out in this Privacy Policy. Continued access or use of Rivelin Robotics Limited will
      constitute your express acceptance of any modifications to this Privacy Policy.
      <br /> <br />
    </React.Fragment>
  ),
  spanText28: (
    <React.Fragment>
      10 INTERPRETATION
      <br />
    </React.Fragment>
  ),
  headerLargeDesktopProps: headerLargeDesktopData,
  footerProps: footerData,
};

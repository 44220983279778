import React from 'react';
import './ButtonHover.scss';

class ButtonHover extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`button-hover ${className || ''}`}>
        <div className='submit-1 atkinsonhyperlegible-bold-white-22px'>Submit</div>
      </div>
    );
  }
}

export default ButtonHover;

import React from 'react';
import './Youtube.scss';
import youTubeImage from '../../static/img/youtube-2@1x.png';

class Youtube extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <a
        href='https://www.youtube.com/channel/UCxc8MtmIqAeZ7yV3PDfmRIw'
        target='_blank'
        alt='YouTube'
        rel='noopener noreferrer'
        title='Visit YouTube Channel'
      >
        <div
          className={`youtube ${className || ''}`}
          style={{ backgroundImage: `url(${youTubeImage})` }}
        ></div>
      </a>
    );
  }
}

export default Youtube;

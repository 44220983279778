import React from 'react';
import { HashLink } from 'react-router-hash-link';
import ApplyNow from './ApplyNow';
import './MblHowWeWork.scss';
import ns1CloseupImage from '../../static/img/ns1-closeup@1x.png';
import maskGroup4 from '../../static/img/mask-group-4@1x.png';

class MblHowWeWork extends React.Component {
  render() {
    const {
      title,
      spanText1,
      spanText2,
      rivelinSCommitted,
      spanText3,
      spanText4,
      areYouComfortable,
      applyNowProps,
    } = this.props;

    return (
      <div className='container-center-horizontal mhww-container'>
        <div className='google-pixel-6-pro-how-we-work'>
          <h1 className='title'>{title}</h1>
          <div className='mhww-ns-0'>
            <div className='mhww-group-35'>
              <div className='mhww-risk-free-deployment atkinsonhyperlegible-bold-bunker-18px'>
                <span className='span0 atkinsonhyperlegible-normal-bunker-18px'>{spanText1}</span>
                <span className='atkinsonhyperlegible-bold-bunker-35px'>{spanText2}</span>
              </div>
            </div>
          </div>
          <div className='mhww-rivelins-committed'>{rivelinSCommitted}</div>
          <div className='overlap-group1'>
            <div className='mhww-ns-1'>
              <div className='mhww-pricing-modelyour-n atkinsonhyperlegible-bold-bunker-18px'>
                <span className='span0 atkinsonhyperlegible-normal-bunker-18px'>{spanText3}</span>
                <span className='atkinsonhyperlegible-bold-bunker-35px'>{spanText4}</span>
              </div>
            </div>
            <img className='ns1-close-up' src={ns1CloseupImage} alt={ns1CloseupImage} />
          </div>
          <div className='mhww-are-you-comfortable'>{areYouComfortable}</div>
          <HashLink to='/contact-us#top'>
            <ApplyNow
              speakWithARivelinExpert={applyNowProps.speakWithARivelinExpert}
              angleRight={applyNowProps.angleRight}
            />
          </HashLink>
          <img className='mhww-mask-group-4' src={maskGroup4} alt={maskGroup4} />
        </div>
      </div>
    );
  }
}

export default MblHowWeWork;

import React from "react";
import "./HeroLargeDesktop3.scss";

class HeroLargeDesktop3 extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="hero-large-desktop-1">
        <div className="title-3">
          <div className="careers-1">{children}</div>
        </div>
      </div>
    );
  }
}

export default HeroLargeDesktop3;

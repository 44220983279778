import React from 'react';
import './Button0.scss';

class Button0 extends React.Component {
  render() {
    const { className, text } = this.props;
    return (
      <div className={`m-button-0 ${className || ''}`}>
        <div className='overlap-group'>
          <div className='rectangle-450'></div>
          <div className='net-shape-1 atkinsonhyperlegible-bold-white-10px'>{text ? text : 'NetShape®'}</div>
        </div>
      </div>
    );
  }
}

export default Button0;

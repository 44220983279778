import React from "react";
import { HashLink } from "react-router-hash-link";
import "./PrivacyPolicyLink.scss";

class PrivacyPolicyLink extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`privacy-policy-link ${className || ""}`}>
        <HashLink to="/privacy-policy#top">
          <div className="privacy-policy atkinsonhyperlegible-normal-white-16px">Privacy Policy</div>
        </HashLink>
      </div>
    );
  }
}

export default PrivacyPolicyLink;

import React from 'react';
import './Instagram.scss';

class Instagram extends React.Component {
  render() {
    return (
      <a
        href='https://www.instagram.com/rivelinrobotics/'
        target='_blank'
        className='align-self-flex-end'
        rel='noopener noreferrer'
        title='Visit instagram Channel'
      >
        <div className='instagram'></div>
      </a>
    );
  }
}

export default Instagram;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import Problem from '../Problem';
import AtAGlance from '../AtAGlance';
import Footer from '../Footer';
import './LrgWhyWeExist.scss';
import sloveIndustryImage from '../../static/img/ewirabracket-1x-png-1x-png@1x.png';
import Line2 from '../../static/img/line-2-1@1x.png';
import angleRight from '../../static/img/angles-right-1@1x.png';

class LrgWhyWeExist extends React.Component {
  render() {
    const {
      place,

      spanText1,
      spanText2,
      weExistToDriveTh,
      overlapGroup,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      spanText11,
      spanText12,
      spanText13,
      headerLargeDesktopProps,
      heroLargeDesktop2Props,
      problem1Props,
      problem2Props,
      problem3Props,
      problem4Props,
      problem5Props,
      problem6Props,
      problem7Props,
      problem8Props,
      atAGlance3Props,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
          />
          <title>Why We Exist | Rivelin</title>
          <meta name='description' content='We exist to drive the mass uptake of metal AM across industry' />
          <meta
            name='keywords'
            content='metal AM, metal additive manufacturing finishing, metal additive manufacturing support removal, metal additive manufacturing post processing, metal additive manufacturing powder removal, metal additive manufacturing automation, NetShape, Rivelin'
          />
          <meta
            property='og:description'
            content='We exist to drive the mass uptake of metal AM across industry'
          />
          <meta property='og:site_name' content='Rivelin Robotics' />
          <meta property='og:title' content='Why We Exist' />
          <meta name='og:image' content='https://www.rivelinrobotics.com/img/rivelintortuga@1x.png' />
          <meta
            name='og:image:secure_url'
            content='https://www.rivelinrobotics.com/img/rivelintortuga@1x.png'
          />
          <meta
            name='image'
            property='og:image'
            content='https://www.rivelinrobotics.com/img/rivelintortuga@1x.png'
          />
          <meta property='og:image:type' content='image/png' />
        </Helmet>
        <div className='lrg-why-we-exist screen'>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
            className={heroLargeDesktop2Props.className}
          />
          <div className='nav-bar-14'>
            <HashLink to='/#top'>
              <div className='place-3 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right-2' src={angleRight} alt={angleRight} />
            <div className='who-we-are-placeholder-1 atkinsonhyperlegible-normal-onyx-22px-2'>
              <span className='span0-7 atkinsonhyperlegible-normal-onyx-22px'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText2}</span>
            </div>
          </div>
          <div className='top-copy-1'>
            <div className='heading-1-2'>
              <div className='we-exist-to-drive-th atkinsonhyperlegible-bold-bunker-40px'>
                {weExistToDriveTh}
              </div>
              <div className='as-additive-manufact atkinsonhyperlegible-normal-bunker-24px'>
                As additive manufacturing (AM) is increasingly adopted as a production technology rather than
                a prototyping technology, it comes under increased scrutiny from a number of different
                perspectives. When used as a prototyping technology, “good enough” may suffice when it comes
                to tolerance attainment, surface finish, or precise geometrical conformity with design intent.
                However, when used for the production of end-use parts, good enough is not good enough, and AM
                needs to be seen to be not just accurate but also repeatably reliable and cost-effective as a
                means of production.
                <br />
                <br />
                Today, there is a huge focus on the post-processing of AM parts and components. Post
                processing is not only costly, in some instances contributing upwards of 30% of unit cost per
                part, but in many instances involves manual intervention which introduces part-to-part quality
                inconsistency, and which is costly, laborious, and in some instances hazardous.
                <br />
                <br />
                The search for automated post-processing solutions is on-going and vital for the mass uptake
                of metal AM across industry, and is the reason that Rivelin Robotics exists.
                <br />
                <br />
                Today, the company focuses on the automation of support removal and targeted finishing to meet
                the standards of mission critical industries, and will introduce an array of complementary
                post-processing processes moving forward including, but not limited to, polishing, platform
                removal, and powder removal.
                <br />
                <br />
                For support removal and targeted finishing, Rivelin has developed NetShape Robots, an enclosed
                microfactory that combines an industrial robot — equipped with an array of tools,
                state-of-the-art sensors and processes to ensure dexterity — driven by the powerful NetShape
                control software which uses both machine learning and traditional deterministic control theory
                to optimise the quality and repeatability of support removal and finishing.
                <br />
                <br />
                The result is an automated support removal solution that reduces defects by 90%, results in a
                10-fold reduction in operational costs, and eliminates human risk and variability.
                <br />
                <br />
                As a company we aim to drive the uptake of AM which we see as a major contributor to a
                sustainable future, and which will have lasting benefits for our environment. With NetShape
                Robots we introduce our first breakthrough to achieve this goal.
              </div>
            </div>
          </div>
          <div className='solutions'>
            <div
              className='split-banner-1'
              style={{ backgroundImage: `url(${sloveIndustryImage})` }}
              id='solutions'
            >
              <div className='overlap-group-28' style={{ backgroundImage: `url(${overlapGroup})` }}>
                <div className='solutionsreliable-m atkinsonhyperlegible-normal-white-22px-2'>
                  <span className='atkinsonhyperlegible-normal-white-22px'>{spanText3}</span>
                  <span className='span1-4 atkinsonhyperlegible-normal-white-34px'>{spanText4}</span>
                  <span className='atkinsonhyperlegible-normal-white-22px'>{spanText5}</span>
                </div>
              </div>
            </div>
            <div className='support-removal'>
              <div className='title-5 atkinsonhyperlegible-bold-onyx-22px'>
                <span className='span0-6 atkinsonhyperlegible-normal-onyx-22px'>{spanText6}</span>
                <span className='atkinsonhyperlegible-bold-onyx-32px'>{spanText7}</span>
              </div>
              <div className='flex-row-10'>
                <Problem
                  problem={problem1Props.problem}
                  additiveManufacturi={problem1Props.additiveManufacturi}
                />
                <Problem
                  problem={problem2Props.problem}
                  additiveManufacturi={problem2Props.additiveManufacturi}
                  className={problem2Props.className}
                />
                <AtAGlance hideAutonomousProgramming={true} hideFlexibleFixturing={false} />
              </div>
            </div>
            <img className='line-2-1' src={Line2} alt={Line2} />
            <div className='finishing-polishing'>
              <div className='title-4 atkinsonhyperlegible-bold-onyx-22px'>
                <span className='span0-6 atkinsonhyperlegible-normal-onyx-22px'>{spanText8}</span>
                <span className='atkinsonhyperlegible-bold-onyx-32px'>{spanText9}</span>
              </div>
              <div className='flex-row-10'>
                <Problem
                  problem={problem3Props.problem}
                  additiveManufacturi={problem3Props.additiveManufacturi}
                  className={problem3Props.className}
                />
                <Problem
                  problem={problem4Props.problem}
                  additiveManufacturi={problem4Props.additiveManufacturi}
                  className={problem4Props.className}
                />
                <AtAGlance hideAutonomousProgramming={true} hideFlexibleFixturing={false} />
              </div>
            </div>
            <img className='line-2-1' src={Line2} alt={Line2} />
            <div className='platform-removal'>
              <div className='title-4 atkinsonhyperlegible-bold-onyx-22px'>
                <span className='span0-6 atkinsonhyperlegible-normal-onyx-22px'>{spanText10}</span>
                <span className='atkinsonhyperlegible-bold-onyx-32px'>{spanText11}</span>
              </div>
              <div className='flex-row-10'>
                <Problem
                  problem={problem5Props.problem}
                  additiveManufacturi={problem5Props.additiveManufacturi}
                  className={problem5Props.className}
                />
                <Problem
                  problem={problem6Props.problem}
                  additiveManufacturi={problem6Props.additiveManufacturi}
                  className={problem6Props.className}
                />
                <AtAGlance hideAutonomousProgramming={false} hideFlexibleFixturing={true} />
              </div>
            </div>
            <img className='line-2-1' src={Line2} alt={Line2} />
            <div className='depowdering'>
              <div className='title-4 atkinsonhyperlegible-bold-onyx-22px'>
                <span className='span0-6 atkinsonhyperlegible-normal-onyx-22px'>{spanText12}</span>
                <span className='atkinsonhyperlegible-bold-onyx-32px'>{spanText13}</span>
              </div>
              <div className='flex-row-10'>
                <Problem
                  problem={problem7Props.problem}
                  additiveManufacturi={problem7Props.additiveManufacturi}
                  className={problem7Props.className}
                />
                <Problem
                  problem={problem8Props.problem}
                  additiveManufacturi={problem8Props.additiveManufacturi}
                  className={problem8Props.className}
                />
                <AtAGlance
                  className={atAGlance3Props.className}
                  hideAutonomousProgramming={true}
                  hideFlexibleFixturing={false}
                />
              </div>
            </div>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default LrgWhyWeExist;

import React from "react";
import HeaderLargeDesktop from "../HeaderLargeDesktop";
import Footer from "../Footer";
import "./LrgPrivacyPolicy.scss";
import {Helmet} from "react-helmet";

class LrgPrivacyPolicy extends React.Component {
  render() {
    const {
      spanText1,
      spanText2,
      spanText4,
      spanText6,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      spanText11,
      spanText12,
      spanText13,
      spanText14,
      spanText16,
      spanText18,
      spanText20,
      spanText21,
      spanText22,
      spanText23,
      spanText24,
      spanText25,
      spanText26,
      spanText27,
      spanText28,
      headerLargeDesktopProps,
      footerProps,
    } = this.props;

    return (
      <div className="lrg-privacy-policy screen">
        <Helmet>
          <meta name="robots" content="noindex" />
          <title>Privacy Policy | Rivelin</title>
          <meta property="og:site_name" content="Rivelin Robotics"/>
          <meta property="og:title" content="Privacy Policy"/>
        </Helmet>
        <HeaderLargeDesktop {...headerLargeDesktopProps} />
        <div className="website-privacy-poli atkinsonhyperlegible-normal-onyx-26px-2">
          <span className="span0-4 atkinsonhyperlegible-bold-onyx-26px-2">{spanText1}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText2}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">
            1.1 Important information and who we are
            <br />
            Welcome to Rivelin Robotics Limited’s Privacy and Data Protection Policy (“Privacy Policy”).
            <br />
            At Rivelin Robotics Limited (“we”, “us”, or “our”) we are committed to protecting and respecting your
            privacy and Personal Data in compliance with the United Kingdom General Data Protection Regulation (“GDPR”),
            the Data Protection Act 2018 and all other mandatory laws and regulations of the United Kingdom.
            <br />
            This Privacy Policy explains how we collect, process and keep your data safe. The Privacy Policy will tell
            you about your privacy rights, how the law protects you, and inform our employees and staff members of all
            their obligations and protocols when processing data.
            <br />
            The individuals from which we may gather and use data can include: <br />• Customers
            <br />• Suppliers <br />• Business contacts
            <br />• Employees/Staff Members
            <br />• Third parties connected to your customers
            <br />
            and any other people that the organisation has a relationship with or may need to contact.
            <br />
            This Privacy Policy applies to all our employees and staff members and all Personal Data processed at any
            time by us.
            <br />
            1.2 Who is Your Data Controller
            <br />
            Rivelin Robotics Limited is your Data Controller and responsible for your Personal Data. We are not obliged
            by the GDPR to appoint a data protection officer and have not voluntarily appointed one at this time.
            Therefore, any inquiries about your data should either be sent to us on email at info@rivelinrobotics.com or
            sent in a letter to Unit 1, Neepsend Triangle, 1 Burton Rd, Sheffield, S3 8BW, United Kingdom.
            <br />
            You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK
            supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance
            to deal with your concerns before you approach the ICO so please contact us in the first instance.
            <br />
            1.3 Processing Data on Behalf of a Controller and Processors’ responsibility to you
            <br />
            In discharging our responsibilities as a Data Controller we have employees who will deal with your data on
            our behalf (known as “Processors”). Therefore, the responsibilities described below may be assigned to an
            individual, or may be taken to apply to the organisation as a whole. The Data Controller and our Processors
            have the following responsibilities:
            <br />• Ensure that all processing of Personal Data is governed by one of the legal bases laid out in the
            GDPR (see 2.2 below for more information on those bases);
            <br />• Ensure that Processors authorised to process Personal Data have committed themselves to
            confidentiality or are under an appropriate statutory obligation of confidentiality;
            <br />• Implement appropriate technical and organisational measures to ensure a level of security
            appropriate to the risk associated with the processing of Personal Data;
            <br />• Obtain the prior specific or general authorisation of the Controller before engaging another
            Processor;
            <br />• Assist the Controller in the fulfilment of the Controller&#39;s obligation to respond to requests
            for exercising the data subject&#39;s rights;
            <br />• Make available to the Controller all information necessary to demonstrate compliance with the
            obligations laid down in the GDPR and allow for and contribute to audits, including inspections, conducted
            by the Controller or another auditor mandated by the Controller;
            <br />• Maintain a record of all categories of processing activities carried out on behalf of a Controller;
            <br />• Cooperate, on request, with the supervisory authority in the performance of its tasks;
            <br />• Ensure that any person acting under the authority of the Processor who has access to Personal Data
            does not process Personal Data except on instructions from the Controller; and
            <br />• Notify the Controller without undue delay after becoming aware of a Personal Data Breach;
            <br />
            <br />
          </span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText4}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">
            <br />
            2.1 Types of Data / Privacy Policy Scope
            <br />
            “Personal Data” means any information about an individual from which that person can be identified. It does
            not include data where the identity has been removed (anonymous data).
            <br />
            We may collect, use, store and transfer different kinds of Personal Data about you which we have grouped
            together below. Not all of the following types of data will necessarily be collected from you but this is
            the full scope of data that we collect and when we collect it from you:
            <br />
            We do not collect any Special Categories of Personal Data about you (this includes details about your race
            or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade
            union membership, information about your health, and genetic and biometric data). Nor do we collect any
            information about criminal convictions and offences.
            <br />
            2.2 The Legal Basis for Collecting That Data
            <br />
            There are a number of justifiable reasons under the GDPR that allow collection and processing of Personal
            Data. The main avenues we rely on are:
            <br />
          </span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText6}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText7}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText8}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText9}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText10}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText11}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText12}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText13}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText14}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">
            <br />
            3.1 Our Uses
            <br />
            We will only use your Personal Data when the law allows us to. Set out below is a table containing the
            different types of Personal Data we collect and the lawful basis for processing that data. Please refer to
            section 2.2 for more information on the lawful basis listed in the table below.
            <br />
            Examples provided in the table below are indicative in nature and the purposes for which we use your data
            may be broader than described but we will never process your data without a legal basis for doing so and it
            is for a related purpose. For further inquiries please contact us.
            <br />
            3.2 Marketing and Content Updates
            <br />
            You will receive marketing and new content communications from us unless you specifically request that you
            would not like to receive these communications. From time to time we may make suggestions and
            recommendations to you about goods or services that may be of interest to you.
            <br />
            3.3 Change of Purpose
            <br />
            We will only use your Personal Data for the purposes for which we collected it, unless we reasonably
            consider that we need to use it for another reason and that reason is compatible with the original purpose.
            If you wish to get an explanation as to how the processing for the new purpose is compatible with the
            original purpose, please contact us.
            <br />
            If we need to use your Personal Data for an unrelated purpose, we will notify you and we will explain the
            legal basis which allows us to do so.
            <br />
            Please note that we may process your Personal Data without your knowledge or consent, in compliance with the
            above rules, where this is required or permitted by law.
            <br />
            <br />
          </span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText16}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">
            <br />
            4.1 How Does Rivelin Robotics Limited Protect Customers&#39; Personal Data?
            <br />
            We are concerned with keeping your data secure and protecting it from inappropriate disclosure. We implement
            a variety of security measures to ensure the security of your Personal Data on our systems, including We
            implement certain security measures to ensure the security of your Personal Data on our systems, including
            specialist cybersecurity software BitDefender Endpoint Security Tools.. Any Personal Data collected by us is
            only accessible by a limited number of employees who have special access rights to such systems and are
            bound by obligations of confidentiality. If and when we use subcontractors to store your data, we will not
            relinquish control of your Personal Data or expose it to security risks that would not have arisen had the
            data remained in our possession. However, unfortunately no transmission of data over the internet is
            guaranteed to be completely secure. It may be possible for third parties not under the control of Rivelin
            Robotics Limited to intercept or access transmissions or private communications unlawfully. While we strive
            to protect your Personal Data, we cannot ensure or warrant the security of any Personal Data you transmit to
            us. Any such transmission is done at your own risk. If you believe that your interaction with us is no
            longer secure, please contact us.
            <br />
            4.2 Opting Out Of Marketing Promotions
            <br />
            You can ask us to stop sending you marketing messages at any time by contacting us at any time.
            <br />
            Where you opt out of receiving these marketing messages, we will continue to retain other Personal Data
            provided to us as a result of interactions with us not related to your marketing preferences.
            <br />
            4.3 How to Request your Data and the Process for Obtaining it
            <br />
            You will not have to pay a fee to access your Personal Data (or to exercise any of the other rights).
            However, if your request is clearly unfounded, we could refuse to comply with your request.
            <br />
            We may need to request specific information from you to help us confirm your identity and ensure you have
            the right to access your Personal Data (or to exercise any of your other rights). This is a security measure
            to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also
            contact you to ask you for further information in relation to your request to speed up our response.
            <br /> <br />
          </span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText18}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">
            <br />
            5.1 Will We Share Your Data With Third Parties?
            <br />
            We may also share Personal Data with interested parties in the event that Rivelin Robotics Limited
            anticipates a change in control or the acquisition of all or part of our business or assets or with
            interested parties in connection with the licensing of our technology.
            <br />
            If Rivelin Robotics Limited is sold or makes a sale or transfer, we may, in our sole discretion, transfer,
            sell or assign your Personal Data to a third party as part of or in connection with that transaction. Upon
            such transfer, the Privacy Policy of the acquiring entity may govern the further use of your Personal Data.
            In all other situations your data will still remain protected in accordance with this Privacy Policy (as
            amended from time to time).
            <br />
            We may share your Personal Data at any time if required for legal reasons or in order to enforce our terms
            or this Privacy Policy.
            <br />
            5.2 Third-Party Links
            <br />
            This Site may include links to third-party websites, plug-ins and applications. Clicking on those links or
            enabling those connections may allow third parties to collect or share data about you. We do not control
            these third-party websites and are not responsible for their privacy statements. When you leave our Site, we
            encourage you to read the privacy policy of every website you visit.
            <br />
          </span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText20}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText21}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText22}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText23}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText24}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText25}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText26}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">{spanText27}</span>
          <span className="atkinsonhyperlegible-bold-onyx-18px">{spanText28}</span>
          <span className="atkinsonhyperlegible-normal-onyx-18px">
            <br />
            All uses of the word &#34;including&#34; mean &#34;including but not limited to&#34; and the enumerated
            examples are not intended to in any way limit the term which they serve to illustrate. Any email addresses
            set out in this policy may be used solely for the purpose for which they are stated to be provided, and any
            unrelated correspondence will be ignored. Unless otherwise required by law, we reserve the right to not
            respond to emails, even if they relate to a legitimate subject matter for which we have provided an email
            address. As a matter of common sense, you are more likely to get a reply if your request or question is
            polite, reasonable and there is no relatively obvious other way to deal with or answer your concern or
            question (e.g. FAQs, other areas of our website etc.).
            <br />
            Our staff are not authorised to contract on behalf of Rivelin Robotics Limited, waive rights or make
            representations (whether contractual or otherwise). If anything contained in an email from a Rivelin
            Robotics Limited address contradicts anything in this policy, our terms or any official public announcement
            on our website, or is inconsistent with or amounts to a waiver of any Rivelin Robotics Limited rights, the
            email content will be read down to grant precedence to the latter. The only exception to this is genuine
            correspondence expressed to be from the Rivelin Robotics Limited legal department.
          </span>
        </div>
        <Footer
          className={footerProps.className}
          privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
          youtubeProps={footerProps.youtubeProps}
          linkedinProps={footerProps.linkedinProps}
          githubProps={footerProps.githubProps}
        />
      </div>
    );
  }
}

export default LrgPrivacyPolicy;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import ApplyNow from '../ApplyNow';
import Footer from '../Footer';
import './LrgHowWeWork.scss';
import MblHowWeWork from '../MblHowWeWork';
import { Helmet } from 'react-helmet';
import angleright from '../../static/img/angles-right-1@1x.png';

const applyNowData = {
  speakWithARivelinExpert: 'SPEAK WITH A RIVELIN EXPERT',
};

const googlePixel6ProHowWeWorkData = {
  title: (
    <React.Fragment>
      NetShape Robots, <br />
      your new team of finishers.
    </React.Fragment>
  ),
  spanText1: (
    <React.Fragment>
      RISK-FREE DEPLOYMENT
      <br />
    </React.Fragment>
  ),
  spanText2: 'Maximise your uptime',
  rivelinSCommitted: (
    <React.Fragment>
      Rivelin&#x27;s committed to supporting you throughout the production journey; NPI (New Product
      Introduction), Pre-Production Trials, FAT (Factory Acceptance Tests), SAT (Site Acceptance Tests),
      In-Production and Post-Production Decommissioning. <br />
      <br />
      The goal is to maximise value for our partners, <br />
      and to achieve this Rivelin takes care of the <br />
      software, hardware, service and preventative maintenance. We work with you every step of the way, from
      our factory floor to yours.
    </React.Fragment>
  ),
  spanText3: (
    <React.Fragment>
      PRICING MODEL
      <br />
    </React.Fragment>
  ),
  spanText4: 'Your new 12-month labour contract',
  ns1Closeup: '../../static/img/ns1-closeup@1x.png',
  areYouComfortable: (
    <React.Fragment>
      Are you comfortable with CapEx? Or would you prefer a more flexible, revenue approach?
      <br />
      <br />
      Think of the Rivelin solution as an OpEx. Think of it as your new 12-month labour contract.
    </React.Fragment>
  ),
  maskGroup4: '/img/mask-group-4@1x.png',
  applyNowProps: applyNowData,
};

class LHowWeWork extends React.Component {
  render() {
    const {
      place,
      spanText1,
      spanText2,
      ns1Closeup,
      spanText3,
      spanText4,
      rivelinSCommitted,
      spanText5,
      spanText6,
      maskGroup4,
      areYouComfortable,
      headerLargeDesktopProps,
      heroLargeDesktop2Props,
      applyNowProps,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
          />
          <title>How We Work | Rivelin</title>
          <meta name='description' content='Your new 12-month labour contract' />
          <meta
            name='keywords'
            content='metal AM production automation, metal am factory automation, robotics-as-a-service'
          />
          <meta property='og:description' content='Your new 12-month labour contract' />
          <meta property='og:site_name' content='Rivelin Robotics' />
          <meta property='og:title' content='How We Work' />
          <meta name='og:image' content='https://www.rivelinrobotics.com/img/ns1-closeup-1@1x.png' />
          <meta
            name='og:image:secure_url'
            content='https://www.rivelinrobotics.com/img/ns1-closeup-1@1x.png'
          />
          <meta
            name='image'
            property='og:image'
            content='https://www.rivelinrobotics.com/img/ns1-closeup-1@1x.png'
          />
          <meta property='og:image:type' content='image/png' />
        </Helmet>
        <div className='l-how-we-work screen'>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
            className={heroLargeDesktop2Props.className}
          />
          <div className='nav-bar-13'>
            <HashLink to='/#top'>
              <div className='place-14 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right-3' src={angleright} alt={angleright} />
            <div className='how-we-work-placeholder atkinsonhyperlegible-normal-onyx-22px-2'>
              <span className='span0-6 atkinsonhyperlegible-normal-onyx-22px'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText2}</span>
            </div>
          </div>
          <MblHowWeWork {...googlePixel6ProHowWeWorkData} />
          <div className='hww-shrinker'>
            <div className='why-we-exist-copy'>
              <div className='overlap-group3-2'>
                <img className='ns1-close-up' src={ns1Closeup} alt={ns1Closeup} />
                <div className='risk-free-deployment atkinsonhyperlegible-bold-bunker-26px'>
                  <span className='atkinsonhyperlegible-normal-bunker-26px'>{spanText3}</span>
                  <span className='atkinsonhyperlegible-bold-bunker-46px'>{spanText4}</span>
                </div>
              </div>
              <div className='rivelins-committed atkinsonhyperlegible-normal-onyx-26px'>
                {rivelinSCommitted}
              </div>
            </div>
            <div className='why-we-exist-copy-1'>
              <div className='overlap-group2-1'>
                <div className='pricing-modelyour-n atkinsonhyperlegible-bold-bunker-26px'>
                  <span className='atkinsonhyperlegible-normal-bunker-26px'>{spanText5}</span>
                  <span className='atkinsonhyperlegible-bold-bunker-46px'>{spanText6}</span>
                </div>
                <img className='mask-group-4' src={maskGroup4} alt={maskGroup4} />
              </div>
              <div className='are-you-comfortable atkinsonhyperlegible-normal-onyx-26px'>
                {areYouComfortable}
              </div>
              <ApplyNow
                applyNow={applyNowProps.applyNow}
                link={applyNowProps.link}
                className={applyNowProps.className}
              />
            </div>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default LHowWeWork;

import React from 'react';
import './Button1.scss';

class Button1 extends React.Component {
  render() {
    const { button1, className } = this.props;

    return (
      <div className={`m-button-1 ${className || ''}`} style={{ backgroundImage: `url(${button1})` }}>
        <div className='the-robots-1 atkinsonhyperlegible-bold-white-10px'>THE ROBOTS</div>
      </div>
    );
  }
}

export default Button1;

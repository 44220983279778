import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './ApplyNow.scss';
import applyImage from '../../static/img/angle-right@1x.png';
import '../../static/img/angles-right-1@1x.png';

const scrollWithOffset = (el, offset) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = offset;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'auto' });
};

class ApplyNow extends React.Component {
  render() {
    const { applyNow, className, link, offset } = this.props;

    return (
      <div className={`apply-now ${className || ''}`}>
        {!!link && (
          <div className='overlap-group1-1'>
            <HashLink to={link} scroll={(el) => scrollWithOffset(el, offset)}>
              <div className='apply-now-1 atkinsonhyperlegible-bold-sea-green-28px'>{applyNow}</div>
            </HashLink>
            <HashLink to={link} scroll={(el) => scrollWithOffset(el, offset)}>
              <div className='right-angle-arrow'>
                <img className='angle-right' src={applyImage} alt={applyImage} />
              </div>
            </HashLink>
          </div>
        )}
        {!link && (
          <div className='overlap-group1-1'>
            <div className='apply-now-1 atkinsonhyperlegible-bold-sea-green-28px'>{applyNow}</div>
            <div className='right-angle-arrow'>
              <img className='angle-right' src={applyImage} alt={applyImage} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ApplyNow;

import React from 'react';
import '../ResourceIndex/ResourceIndex.scss';

class ResourceIndex extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className='resource-entry-card'>
        <div className='card-container'>
          <img
            className='preview-image'
            src={data.fields.featuredImage.fields.file.url}
            alt={data.fields.title}
          />
          <br />
          <br />
          <span className='atkinsonhyperlegible-bold-onyx-20px-2'>{data.fields.title}</span>
          <br />
          <br />
          <span className='atkinsonhyperlegible-normal-onyx-18px'>{data.fields.description}</span>
          <br />
          <br />
          <div className='small'>{new Date(data.fields.date).toDateString()}</div>
        </div>
      </div>
    );
  }
}

export default ResourceIndex;

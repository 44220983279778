import React from 'react';
import './Button04.scss';

class Button04 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`button-0-2-1 ${className || ''}`}>
        <div className='overlap-group-13'>
          <div className='rectangle-450-1'></div>
          <div className='connect-with-an-engineer atkinsonhyperlegible-bold-black-18px'>
            CONNECT WITH AN ENGINEER
          </div>
        </div>
      </div>
    );
  }
}

export default Button04;

import React from 'react';
import '../HeroLargeDesktop2/HeroLargeDesktop2.scss';

class HeroLargeDesktop2 extends React.Component {
  render() {
    const { heroLargeDesktop2, title, className } = this.props;
    return (
      <div className={`hero-large-desktop2 ${className || ''}`}>
        <img src={heroLargeDesktop2} alt='not found' />
        <div className='title-1'>
          <h1 className='title-2 atkinsonhyperlegible-bold-white-65px'>{title}</h1>
        </div>
      </div>
    );
  }
}

export default HeroLargeDesktop2;

import React from 'react';
import './Story1.scss';
import Image3 from '../../static/img/TomNoble.jpeg';
import Image1 from '../../static/img/image-1-1x-png@1x.png';
import closeIcon from '../../static/img/xmark@1x.png';
import Image2 from '../../static/img/image-2-1x-png@1x.png';
import eyeIcon from '../../static/img/eye-1@1x.png';

class Story1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dmBioVisible: false,
      daBioVisible: false,
      saBioVisible: false,
    };
  }

  render() {
    const {
      spanText1,
      spanText2,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      readTheirStory1Props,
      readTheirStory2Props,
      dm_bio,
      da_bio,
      sa_bio,
    } = this.props;

    return (
      <div className='story-1'>
        <div className='overlap-group-container-1'>
          <div className='overlap-group2'>
            <div className='overlap-group-14'>
              <img className='image' src={Image1} alt={Image1} />
              {!!this.state.dmBioVisible && (
                <div className='bio-wrapper' onClick={() => this.setState({ dmBioVisible: false })}>
                  <img className='icon-close' src={closeIcon} alt={closeIcon} />
                  <div className='bio-text atkinsonhyperlegible-normal-gallery-24px'>
                    {dm_bio.map((t) => (
                      <div>
                        <span>{t}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className='name atkinsonhyperlegible-bold-onyx-20px'>
              <span className='span0-3 atkinsonhyperlegible-bold-onyx-20px-2'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-18px'>{spanText2}</span>
              <span className='atkinsonhyperlegible-bold-onyx-18px-2'>{spanText3}</span>
            </div>
            <div className='read-their-story-2' onClick={() => this.setState({ dmBioVisible: true })}>
              <div className='overlap-group1'>
                <div className='read-their-story fontawesome6freesolid-normal-sea-green-18px'>
                  <span className='span-14 atkinsonhyperlegible-normal-sea-green-18px'>
                    &nbsp;&nbsp;&nbsp;&nbsp; Read their story
                  </span>
                </div>
                <img className='icon-eye' src={eyeIcon} alt={eyeIcon} />
              </div>
            </div>
          </div>
          <div className='overlap-group3'>
            <div className='overlap-group-14'>
              <img className='image' src={Image2} alt={Image2} />
              {!!this.state.daBioVisible && (
                <div className='bio-wrapper' onClick={() => this.setState({ daBioVisible: false })}>
                  <img className='icon-close' src={closeIcon} alt={closeIcon} />
                  <div className='bio-text atkinsonhyperlegible-normal-gallery-24px'>
                    {da_bio.map((t) => (
                      <div>
                        <span>{t}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className='name atkinsonhyperlegible-bold-onyx-20px'>
              <span className='span0-3 atkinsonhyperlegible-bold-onyx-20px-2'>{spanText4}</span>
              <span className='atkinsonhyperlegible-normal-onyx-18px'>{spanText5}</span>
              <span className='atkinsonhyperlegible-bold-onyx-18px-2'>{spanText6}</span>
            </div>
            <div
              className={`read-their-story-2 ${readTheirStory1Props.className || ''}`}
              onClick={() => this.setState({ daBioVisible: true })}
            >
              <div className='overlap-group1'>
                <div className='read-their-story fontawesome6freesolid-normal-sea-green-18px'>
                  <span className='span-14 atkinsonhyperlegible-normal-sea-green-18px'>
                    &nbsp;&nbsp;&nbsp;&nbsp; Read their story
                  </span>
                </div>
                <img className='icon-eye' src={eyeIcon} alt={eyeIcon} />
              </div>
            </div>
          </div>
          <div className='overlap-group4'>
            <div className='overlap-group-14'>
              <img className='image' src={Image3} alt='Image3' />
              {!!this.state.saBioVisible && (
                <div className='bio-wrapper' onClick={() => this.setState({ saBioVisible: false })}>
                  <img className='icon-close' src={closeIcon} alt={closeIcon} />
                  <div className='bio-text atkinsonhyperlegible-normal-gallery-24px'>
                    {sa_bio.map((t) => (
                      <div>
                        <span>{t}</span>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className='name atkinsonhyperlegible-bold-onyx-20px'>
              <span className='span0-3 atkinsonhyperlegible-bold-onyx-20px-2'>{spanText7}</span>
              <span className='atkinsonhyperlegible-bold-onyx-18px'>{spanText8}</span>
              <span className='atkinsonhyperlegible-normal-onyx-18px'>{spanText9}</span>
              <span className='atkinsonhyperlegible-bold-onyx-18px-2'>{spanText10}</span>
            </div>
            <div
              className={`read-their-story-2 ${readTheirStory2Props.className || ''}`}
              onClick={() => this.setState({ saBioVisible: true })}
            >
              <div className='overlap-group1'>
                <div className='read-their-story fontawesome6freesolid-normal-sea-green-18px'>
                  <span className='span-14 atkinsonhyperlegible-normal-sea-green-18px'>
                    &nbsp;&nbsp;&nbsp;&nbsp; Read their story
                  </span>
                </div>
                <img className='icon-eye' src={eyeIcon} alt={eyeIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Story1;

import React from 'react';
import NS1 from './NS1';
import './MblLearnTheTech.scss';
import ApplyNow from '../ApplyNow';
import NsImage from '../../static/img/mask-group-5-1@1x.png';
import maskGroup6 from '../../static/img/mask-group-6-1x-png@1x.png';
import maskGroup7 from '../../static/img/mask-group-7@1x.png';

class MblLearnTheTech extends React.Component {
  render() {
    const {
      title,
      spanText1,
      spanText2,
      rivelinSSystemSca,
      rivelinSSystemRec,
      theRivelinSystemU,
      nS11Props,
      nS12Props,
    } = this.props;

    return (
      <div className='container-center-horizontal mltt-container'>
        <div className='google-pixel-6-pro-learn-the-tech'>
          <h1 className='title atkinsonhyperlegible-bold-bunker-27px'>{title}</h1>
          <div className='ns-0'>
            <div className='group-35'>
              <div className='oneit-sees atkinsonhyperlegible-bold-bunker-18px'>
                <span className='span0 atkinsonhyperlegible-normal-bunker-18px'>{spanText1}</span>
                <span className='atkinsonhyperlegible-bold-bunker-35px'>{spanText2}</span>
              </div>
            </div>
          </div>
          <div className='rivelins-system-sca atkinsonhyperlegible-normal-onyx-18px'>{rivelinSSystemSca}</div>
          <img className='mask-group-5' src={NsImage} alt={NsImage} />
          <NS1 spanText1={nS11Props.spanText1} spanText2={nS11Props.spanText2} />
          <div className='rivelins-system-rec atkinsonhyperlegible-normal-onyx-18px'>{rivelinSSystemRec}</div>
          <img className='mask-group-6' src={maskGroup6} alt={maskGroup6} />
          <NS1 spanText1={nS12Props.spanText1} spanText2={nS12Props.spanText2} />
          <div className='the-rivelin-system-u atkinsonhyperlegible-normal-onyx-18px'>
            {theRivelinSystemU}
          </div>
          <ApplyNow
            applyNow='Read About Our Solutions'
            link='/why-we-exist#solutions'
            className='solutions-link mobile-version'
            offset={-80}
          />
          <img className='mask-group-7' src={maskGroup7} alt={maskGroup7} />
        </div>
      </div>
    );
  }
}

export default MblLearnTheTech;

import React from "react";
import "./PrimaryButton3.scss";

class PrimaryButton3 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`button-3 ${className || ""}`}>
        <div className="button-open-positions atkinsonhyperlegible-bold-white-18px">OPEN POSITIONS</div>
      </div>
    );
  }
}

export default PrimaryButton3;

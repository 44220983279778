import React from 'react';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import ButtonHover from '../ButtonHover';
import Footer from '../Footer';
import './LrgContactUs.scss';

const siteKey = process.env.REACT_APP_SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default class LrgContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      message: '',
      submitSuccess: false,
      submitFailed: false,
    };
    this.recaptchaRef = React.createRef();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = token => {
    this.setState({ "g-recaptcha-response": token });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const form = e.target;
    const token = await this.recaptchaRef.current.executeAsync();
    await new Promise(resolve => {
      this.setState({ "g-recaptcha-response": token }, resolve);
    });
    fetch('/special-form-handler', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': form.getAttribute('name'),
      ...this.state,
    }),
    })
    .then(response => {
      if (response.ok) {
        console.log('Form submitted successfully');
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          companyName: '',
          submitSuccess: true,
          submitFailed: false,
        });
        this.recaptchaRef.current.reset();
       } else {
      console.error(`HTTP error! status: ${response.status}`);
      this.setState({ submitSuccess: false, submitFailed: true });
      }
    })
    .catch(error => {
      console.error('Form submission error:', error);
      this.setState({ submitSuccess: false, submitFailed: true });
    });
  };

  render() {
    const {
      firstName,
      inputType1,
      inputPlaceholder1,
      lastName,
      inputType2,
      inputPlaceholder2,
      spanText1,
      spanText2,
      inputType3,
      inputPlaceholder3,
      spanText3,
      spanText4,
      headerLargeDesktopProps,
      heroLargeDesktop2Props,
      email1Props,
      email2Props,
      buttonHoverProps,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <div className='lrg-contact-us screen'>
          <Helmet>
            <meta
              name='robots'
              content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
            />
            <title>Contact Us | Rivelin</title>
            <meta name='description' content='Connect with an engineer and Rivelin expert' />
            <meta
              name='keywords'
              content='Rivelin expert, Rivelin engineer, Rivelin quote, Rivelin contact'
            />
            <meta property='og:description' content='Connect with an engineer and Rivelin expert' />
            <meta property='og:site_name' content='Rivelin Robotics' />
            <meta property='og:title' content='Contact Us' />
            <meta name='og:image' content='https://www.rivelinrobotics.com/img/image-1x-png-1x-png@1x.png' />
            <meta
              name='og:image:secure_url'
              content='https://www.rivelinrobotics.com/img/image-1x-png-1x-png@1x.png'
            />
            <meta
              name='image'
              property='og:image'
              content='https://www.rivelinrobotics.com/img/image-1x-png-1x-png@1x.png'
            />
            <meta property='og:image:type' content='image/png' />
          </Helmet>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props?.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
            className={heroLargeDesktop2Props.className}
          />
          <div className='cu-shrinker'>
          <form
            name="contact"
            onSubmit={this.handleSubmit}
            data-netlify="true"
            data-netlify-recaptcha="true"
          >
            <div className='flex-row-6'>
              <div className='form'>
                <div className='first-name'>
                  <div className='overlap-group-15'>
                    <div className='field border-2px-gallery'></div>
                    <div className='first-name-1 atkinsonhyperlegible-normal-bunker-26px'>{firstName}</div>
                    <input
                      className='x atkinsonhyperlegible-normal-onyx-26px'
                      name='firstName'
                      placeholder={inputPlaceholder1}
                      type={inputType1}
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='last-name'>
                  <div className='overlap-group-15'>
                    <div className='field border-2px-gallery'></div>
                    <div className='last-name-1 atkinsonhyperlegible-normal-bunker-26px'>{lastName}</div>
                    <input
                      className='x-1 atkinsonhyperlegible-normal-onyx-26px'
                      name='lastName'
                      placeholder={inputPlaceholder2}
                      type={inputType2}
                      value={this.state.lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='form-item'>
                  <div className='overlap-group-15'>
                    <div className='field-2 border-2px-gallery'></div>
                    <div className='email atkinsonhyperlegible-normal-rose-26px'>
                      <span className='atkinsonhyperlegible-normal-bunker-26px'>
                        {email1Props.spanText1}
                      </span>
                      <span className='atkinsonhyperlegible-normal-rose-26px-2'>*</span>
                    </div>
                    <input
                      className='x-3 atkinsonhyperlegible-normal-onyx-26px'
                      name='email'
                      placeholder=' '
                      type='email'
                      required
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='form-item'>
                  <div className='overlap-group-15'>
                    <div className='field-2 border-2px-gallery'></div>
                    <div className='email atkinsonhyperlegible-normal-rose-26px'>
                      <span className='atkinsonhyperlegible-normal-bunker-26px'>
                        {email2Props.spanText1}
                      </span>
                      <span className='atkinsonhyperlegible-normal-rose-26px-2'>*</span>
                    </div>
                    <input
                      className='x-3 atkinsonhyperlegible-normal-onyx-26px'
                      name='companyName'
                      placeholder=' '
                      type='text'
                      required
                      value={this.state.companyName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='message'>
                  <div className='overlap-group3-1'>
                    <div className='field-1 border-2px-gallery'></div>
                    <div className='message-1 atkinsonhyperlegible-normal-rose-26px'>
                      <span className='atkinsonhyperlegible-normal-bunker-26px'>{spanText1}</span>
                      <span className='atkinsonhyperlegible-normal-rose-26px-2'>{spanText2}</span>
                    </div>
                    <textarea
                      className='x-2 atkinsonhyperlegible-normal-onyx-26px'
                      name='message'
                      placeholder={inputPlaceholder3}
                      type={inputType3}
                      value={this.state.message}
                      onChange={this.handleChange}
                      required
                    ></textarea>
                  </div>
                </div>
                {!!this.state.submitSuccess && (
                  <div className='submit-success'>
                    <span className='atkinsonhyperlegible-normal-bunker-24px'>
                      Success! We'll be in touch.
                    </span>
                  </div>
                )}
                {!!this.state.submitFailed && (
                  <div className='submit-failure'>
                    <span className='atkinsonhyperlegible-normal-bunker-24px'>
                      Woops! Something went wrong, try again or contact info@rivelinrobotics.com
                    </span>
                  </div>
                )}
                <ReCAPTCHA
                ref={this.recaptchaRef}
                size='invisible'
                sitekey={siteKey}
                tabindex={3}
                onChange={this.handleRecaptcha}
                />
                <div className='submit'>
                  <button type='submit' className='button-container-2'>
                    <ButtonHover />
                    <ButtonHover className={buttonHoverProps.className} />
                  </button>
                </div>
              </div>
              <div className='map'>
                <div className='rivelin-roboticsuni atkinsonhyperlegible-normal-onyx-26px-2'>
                  <span className='atkinsonhyperlegible-bold-onyx-26px-2'>{spanText3}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText4}</span>
                </div>
                <div className='rectangle-459'>
                  <iframe
                    title='no-referrer-when-downgrad'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2379.345323479809!2d-1.4748807837945668!3d53.390762379083974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487191c01b21528b%3A0x8f86dbb89ffcadbf!2sRivelin%20Robotics!5e0!3m2!1sen!2suk!4v1662367364362!5m2!1sen!2suk'
                    allowFullScreen=''
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'
                  ></iframe>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Footer
          className={footerProps.className}
          privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
          youtubeProps={footerProps.youtubeProps}
          linkedinProps={footerProps.linkedinProps}
          githubProps={footerProps.githubProps}
        />
        </div>
      </div>
    );
  }
}
import React from 'react';
import './Github.scss';

class Github extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <a
        href='https://github.com/rivelinrobotics'
        target='_blank'
        className='align-self-flex-end'
        rel='noopener noreferrer'
        title='Visit github Channel'
      >
        <div className={`github ${className || ''}`}></div>
      </a>
    );
  }
}

export default Github;

import React from 'react';
import './Linkedin.scss';
import linkdinImage from '../../static/img/linkedin-1@1x.png';

class Linkedin extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <a
        href='https://www.linkedin.com/company/19031167'
        target='_blank'
        className='align-self-flex-start'
        rel='noopener noreferrer'
        title='Visit linkedin Channel'
      >
        <div
          className={`linkedin ${className || ''}`}
          style={{ backgroundImage: `url(${linkdinImage})` }}
        ></div>
      </a>
    );
  }
}

export default Linkedin;

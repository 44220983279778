import React from "react";
import { HashLink } from "react-router-hash-link";
import HeaderLargeDesktop from "../HeaderLargeDesktop";
import Footer from "../Footer";
import "./LrgFAQ.scss";
import {Helmet} from "react-helmet";


class LrgFAQ extends React.Component {
    render() {
        const {
            place,
            anglesRight,
            spanText1,
            spanText2,
            headerLargeDesktopProps,
            footerProps,
        } = this.props;

        return (
            <div className="container-center-horizontal">
                <div className="lrg-faq screen">
                    <Helmet>
                        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                        <title>FAQ | Rivelin</title>
                        <meta name="description" content="Frequently Asked Questions regarding deploying NetShape Robots on metal AM post processing production lines" />
                        <meta name="keywords" content="FAQ, frequently asked questions, 24/7 manufacturing, lights out production, MES integration, ERP system, integrated CMMS, part geometry, support removal examples, datasheet, brochure, spec sheet, training, maintenance, traceability data, part marking, platform removal, caked powder removal, fixture parts, fixturing, examples" />
                        <meta property="og:description" content="Frequently Asked Questions regarding deploying NetShape Robots on metal AM post processing production lines" />
                        <meta property="og:site_name" content="Rivelin Robotics"/>
                        <meta property="og:title" content="FAQ"/>
                        <meta name="og:image" content="https://www.rivelinrobotics.com/img/FAQ_wood.jpg"/>
                        <meta name="og:image:secure_url" content="https://www.rivelinrobotics.com/img/FAQ_wood.jpg"/>
                        <meta name="image" property="og:image" content="https://www.rivelinrobotics.com/img/FAQ_wood.jpg"/>
                        <meta property="og:image:type" content="image/png"/>
                        <script src="https://documentcloud.adobe.com/view-sdk/viewer.js"></script>
                        <script type="text/javascript">
                            {`document.addEventListener("adobe_dc_view_sdk.ready", function(){
                            var adobeDCView = new AdobeDC.View({clientId: "efb2d9d10c554639958b95d93986c439", divId: "adobe-dc-view"});
                            adobeDCView.previewFile({
                            content:{location: {url: "https://storage.googleapis.com/www.old.additiveautomations.com/Website_FAQ_NetShape.pdf"}},
                            metaData:{fileName: "FAQ_NetShape.pdf"}
                            }, {embedMode: "IN_LINE"});
                            });`}
                        </script>
                    </Helmet>
                    <HeaderLargeDesktop {...headerLargeDesktopProps} />
                    <div className="nav-bar-20">
                        <HashLink to="/#top" >
                            <div className="place-9 atkinsonhyperlegible-normal-onyx-22px">{place}</div>
                        </HashLink>
                        <img className="angles-right-8" src={anglesRight} alt={anglesRight}/>
                        <div className="who-we-are-placeholder-6 atkinsonhyperlegible-normal-onyx-22px-2">
                            <span className="span0-13 atkinsonhyperlegible-normal-onyx-22px">{spanText1}</span>
                            <span className="atkinsonhyperlegible-normal-onyx-22px">{spanText2}</span>
                        </div>
                    </div>
                    <div className="adobe-dc-container">
                        <div id="adobe-dc-view" style={{width: `100%`}}></div>
                    </div>
                    <Footer
                        className={footerProps.className}
                        privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
                        youtubeProps={footerProps.youtubeProps}
                        linkedinProps={footerProps.linkedinProps}
                        githubProps={footerProps.githubProps}
                    />
                </div>
            </div>
        );
    }
}

export default LrgFAQ;

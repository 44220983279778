import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import SmallTopLink from '../SmallTopLink';
import SearchBar from '../SearchBar';
import MobileSearch from '../MobileSearch';
import PrimaryButton from '../PrimaryButton';
import DropdownLink from '../DropdownLink';
import LargeHeaderLink from '../LargeHeaderLink';
import '../HeaderLargeDesktop/HeaderLargeDesktop.scss';
import logoImage from '../../static/img/rr-logo-lgt-1@1x.png';

const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algoliaApiSearchKey = process.env.REACT_APP_ALGOLIA_API_SEARCH_KEY;
const algoliaSiteId = process.env.REACT_APP_ALGOLIA_SITE_ID;
const algoliaGitBranch = process.env.REACT_APP_ALGOLIA_GIT_BRANCH;

class HeaderLargeDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayingDropdown: false,
      displayingExtraPages: false,
    };
  }

  componentDidMount() {
    window.algoliasearchNetlify({
      appId: algoliaAppId,
      apiKey: algoliaApiSearchKey,
      siteId: algoliaSiteId,
      branch: algoliaGitBranch,
      selector: 'div#search',
      theme: {
        mark: '#fdcb02',
        background: '#121213',
        selected: '#ffffff',
        text: '#309e56',
        colorSourceIcon: '#309e56',
      },
    });
  }

  enableDropdown = () => {
    this.setState({ displayingDropdown: !this.state.displayingDropdown }, () => {
      window.algoliasearchNetlify({
        appId: algoliaAppId,
        apiKey: algoliaApiSearchKey,
        siteId: algoliaSiteId,
        branch: algoliaGitBranch,
        selector: 'div#mobile-search',
      });
    });
  };

  disableDropdown = (e) => {
    if (e.target && e.target.className.includes('aa-')) {
      e.target.addEventListener('blur', () => this.setState({ displayingDropdown: false }));
      return;
    } else if (e.relatedTarget && e.relatedTarget.className.includes('aa-')) {
      e.relatedTarget.addEventListener('blur', () => this.setState({ displayingDropdown: false }));
      return;
    }
    this.setState({ displayingDropdown: false });
  };

  render() {
    const {
      className,
      contactUsLinkProps,
      careersLinkProps,
      resourcesLinkProps,
      faqLinkProps,
      roboticFinishingSystemsProps,
      howWeWorkProps,
      whoWeAreProps,
      whyWeExistProps,
    } = this.props;

    return (
      <div className='header-wrapper'>
        <div className={`header-large-desktop ${className || ''}`}>
          <div className='nav-bar'>
            <SmallTopLink className={careersLinkProps.className} link={careersLinkProps.link}>
              {careersLinkProps.children}
            </SmallTopLink>
            <SmallTopLink className={resourcesLinkProps.className} link={resourcesLinkProps.link}>
              {resourcesLinkProps.children}
            </SmallTopLink>
            <SmallTopLink className={faqLinkProps.className} link={faqLinkProps.link}>
              {faqLinkProps.children}
            </SmallTopLink>
            <SearchBar />
          </div>
          <div className='flex-row'>
            <div className='group-20'>
              <div className='request-a-demo'>
                <div className='primary-button-container'>
                  <PrimaryButton className='' />
                  <PrimaryButton className='primary-button-1' />
                </div>
              </div>
              <DropdownLink link={roboticFinishingSystemsProps.link}>
                {roboticFinishingSystemsProps.children}
              </DropdownLink>
              <LargeHeaderLink className={howWeWorkProps.className} link={howWeWorkProps.link}>
                {howWeWorkProps.children}
              </LargeHeaderLink>
              <LargeHeaderLink className={whoWeAreProps.className} link={whoWeAreProps.link}>
                {whoWeAreProps.children}
              </LargeHeaderLink>
              <LargeHeaderLink className={whyWeExistProps.className} link={whyWeExistProps.link}>
                {whyWeExistProps.children}
              </LargeHeaderLink>
            </div>
            <HashLink to='/#top'>
              <img className='rr_logo_lgt' src={logoImage} alt='logoImageNotFound' />
            </HashLink>
          </div>
        </div>
        {/* <div className='announcement-banner'>
          <span className='span atkinsonhyperlegible-bold-bunker-20px'>
            Visit us at Automatica June 27-30 in Munich.{' '}
            <a href='https://book.rivel.in/automatica'>Book a meeting here</a> booth B6.502
          </span>
        </div> */}
        <div className='header-small'>
          <div className='expand-icon' tabIndex='0' onBlur={this.disableDropdown}>
            <FontAwesomeIcon icon={icon({ name: 'bars', style: 'solid' })} onClick={this.enableDropdown} />
            {this.state.displayingDropdown && (
              <div className='dropdown'>
                <LargeHeaderLink className='request-a-demo-link' link='/request-a-demo#top'>
                  Request A Demo
                </LargeHeaderLink>
                <div
                  className='robotic-finishing-systems-wrapper'
                  onClick={() => this.setState({ displayingExtraPages: !this.state.displayingExtraPages })}
                >
                  <div className='robotic-finishing-div'>
                    <div className='atkinsonhyperlegible-bold-white-23px'>Robotic Finishing Systems</div>
                    <FontAwesomeIcon
                      icon={icon({ name: 'angle-down', style: 'solid' })}
                      rotation={this.state.displayingExtraPages ? 180 : 0}
                    />
                  </div>
                  {this.state.displayingExtraPages && (
                    <div className='robotic-finishing-dropdown'>
                      <HashLink to='/meet-the-robots#top'>
                        <div className='shd-text'>
                          <span className='atkinsonhyperlegible-normal-white-20px'>Meet The Robots</span>
                        </div>
                      </HashLink>
                      <HashLink to='/learn-the-tech#top'>
                        <div className='shd-text'>
                          <span className='atkinsonhyperlegible-normal-white-20px'>Learn The Technology</span>
                        </div>
                      </HashLink>
                      <HashLink to='/see-it-in-action#top'>
                        <div className='shd-text'>
                          <span className='atkinsonhyperlegible-normal-white-20px'>See It In Action</span>
                        </div>
                      </HashLink>
                    </div>
                  )}
                </div>
                <LargeHeaderLink className={howWeWorkProps.className} link={howWeWorkProps.link}>
                  {howWeWorkProps.children}
                </LargeHeaderLink>
                <LargeHeaderLink className={whoWeAreProps.className} link={whoWeAreProps.link}>
                  {whoWeAreProps.children}
                </LargeHeaderLink>
                <LargeHeaderLink className={whyWeExistProps.className} link={whyWeExistProps.link}>
                  {whyWeExistProps.children}
                </LargeHeaderLink>
                <LargeHeaderLink className={contactUsLinkProps.className} link={contactUsLinkProps.link}>
                  {contactUsLinkProps.children}
                </LargeHeaderLink>
                <LargeHeaderLink className={careersLinkProps.className} link={careersLinkProps.link}>
                  {careersLinkProps.children}
                </LargeHeaderLink>
                <LargeHeaderLink className={resourcesLinkProps.className} link={resourcesLinkProps.link}>
                  {resourcesLinkProps.children}
                </LargeHeaderLink>
                <LargeHeaderLink className={faqLinkProps.className} link={faqLinkProps.link}>
                  {faqLinkProps.children}
                </LargeHeaderLink>
                <div>
                  <a
                    href='tel:+441147003984'
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Visit +44 114 700 3984 Channel'
                  >
                    <div className='link-1 atkinsonhyperlegible-bold-white-23px'>
                      <span className='span atkinsonhyperlegible-bold-white-23px'>+44 114 700 3984</span>
                    </div>
                  </a>
                </div>
                <MobileSearch />
              </div>
            )}
          </div>
          <HashLink to='/#top'>
            <img className='rr_logo_lgt' src={logoImage} alt='logoNotFound' />
            {/* <img src='../../static/img/rr-logo-lgt-1@1x.png/> */}
          </HashLink>
        </div>
      </div>
    );
  }
}

export default HeaderLargeDesktop;

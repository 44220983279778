import React from "react";
import "./PrimaryButton3.scss";

class PrimaryButton3 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`mwwa-button-3 ${className || ""}`}>
        <div className="mwwa-button-open-positions atkinsonhyperlegible-bold-white-12px">OPEN POSITIONS</div>
      </div>
    );
  }
}

export default PrimaryButton3;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Button0 from './Button0';
import './MblMeetTheRobots.scss';
import NsImage from '../../static/img/mask-group-5@1x.png';
import maskGroup6 from '../../static/img/mask-group-6@1x.png';
import maskGroup7 from '../../static/img/mask-group-7@1x.png';
class MblMeetTheRobots extends React.Component {
  render() {
    const {
      title,
      spanText1,
      spanText2,
      spanText3,
      spanText4,
      spanText5,
      spanText6,
      spanText7,
      spanText8,
      spanText9,
      spanText10,
      spanText11,
      spanText12,
      spanText13,
      spanText14,
      spanText15,
      spanText16,
      button01Props,
      button02Props,
      button03Props,
      button04Props,
      button05Props,
    } = this.props;

    return (
      <div className='container-center-horizontal mmtr-container'>
        <div className='google-pixel-6-pro-meet-the-robots'>
          <div className='title'>{title}</div>
          <div className='ns-0'>
            <div className='group-container'>
              <img className='mask-group-5' src={NsImage} alt={NsImage} />
              <div className='group-35'>
                <div className='prototypingns-0 atkinsonhyperlegible-bold-bunker-18px'>
                  <span className='span0 atkinsonhyperlegible-normal-bunker-18px'>{spanText1}</span>
                  <span className='atkinsonhyperlegible-bold-bunker-36px'>{spanText2}</span>
                </div>
                <h1 className='the-ns-0-takes-you-f atkinsonhyperlegible-normal-onyx-35px'>
                  <span className='span0-1 atkinsonhyperlegible-bold-bunker-36px'>{spanText3}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-16px'>{spanText4}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-16px'>{spanText5}</span>
                  <span className='atkinsonhyperlegible-normal-onyx-16px'>{spanText6}</span>
                </h1>
                <HashLink to='/contact-us#top'>
                  <div className='b-net-shape'>
                    <div className='button-container'>
                      <Button0 />
                      <Button0 className={button01Props.className} />
                    </div>
                  </div>
                </HashLink>
              </div>
            </div>
          </div>
          <div className='ns-1'>
            <div className='overlap-group4'>
              <img className='mask-group-6' src={maskGroup6} alt={maskGroup6} />
              <div className='factory-productionns-1 atkinsonhyperlegible-bold-bunker-18px'>
                <span className='span0 atkinsonhyperlegible-normal-bunker-18px'>{spanText7}</span>
                <span className='atkinsonhyperlegible-bold-bunker-36px'>{spanText8}</span>
              </div>
              <div className='the-ns-1-takes-you-i atkinsonhyperlegible-normal-onyx-35px'>
                <span className='span0-1 atkinsonhyperlegible-bold-bunker-36px'>{spanText9}</span>
                <span className='atkinsonhyperlegible-normal-onyx-16px'>{spanText10}</span>
                <span className='atkinsonhyperlegible-normal-onyx-16px'>{spanText11}</span>
                <span className='atkinsonhyperlegible-normal-onyx-16px'>{spanText12}</span>
              </div>
            </div>
            <HashLink to='/contact-us#top'>
              <div className='button-container-1'>
                <Button0 className={button02Props.className} />
                <Button0 className={button03Props.className} />
              </div>
            </HashLink>
          </div>
          <div className='ns-2'>
            <div className='overlap-group3'>
              <img className='mask-group-7' src={maskGroup7} alt={maskGroup7} />
              <div className='the-ns-2-takes-you-f atkinsonhyperlegible-normal-onyx-35px'>
                <span className='span0-1 atkinsonhyperlegible-bold-bunker-36px'>{spanText13}</span>
                <span className='atkinsonhyperlegible-normal-onyx-16px'>{spanText14}</span>
              </div>
              <div className='in-field-productionns-2 atkinsonhyperlegible-bold-bunker-18px'>
                <span className='span0 atkinsonhyperlegible-normal-bunker-18px'>{spanText15}</span>
                <span className='atkinsonhyperlegible-bold-bunker-36px'>{spanText16}</span>
              </div>
            </div>
            <HashLink to='/contact-us#top'>
              <div className='button-container-2'>
                <Button0 className={button04Props.className} />
                <Button0 className={button05Props.className} />
              </div>
            </HashLink>
          </div>
        </div>
      </div>
    );
  }
}

export default MblMeetTheRobots;

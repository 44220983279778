import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import ResourceIndex from '../ResourceIndex';
import ResourcePage from '../ResourcePage';
import Footer from '../Footer';
import '../LrgResources/LrgResources.scss';
import { Helmet } from 'react-helmet';

const contentfulSpaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const contentfulApiKey = process.env.REACT_APP_CONTENTFUL_API_KEY;
function toHumanReadable(str) {
  str = str.charAt(0).toUpperCase() + str.slice(1);
  str = str.replace(/([a-z](?=[A-Z]))/g, '$1 ');
  return str + 's';
}

class LrgResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      pages: [],
      filteredPages: [],
      tags: [],
      selectedTag: '',
    };
  }

  componentDidMount() {
    const contentful = require('contentful');
    const client = contentful.createClient({
      space: contentfulSpaceId,
      accessToken: contentfulApiKey,
    });
    client
      .getEntries()
      .then((response) => {
        response.items.sort((a, b) => (a.fields.date > b.fields.date ? -1 : 1));
        let tags = response.items.reduce(
          (arr, entry) => arr.concat(entry.metadata.tags.map((tagobj) => toHumanReadable(tagobj.sys.id))),
          []
        );
        tags = [...new Set(tags)];
        this.setState({
          loading: false,
          error: false,
          pages: response.items,
          filteredPages: response.items,
          tags: tags,
          selectedTag: '',
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, error: true, pages: [], tags: [], selectedTag: '' });
      });
  }

  handleTagSelection = (e) => {
    let selectedTag = e.target.innerText;
    if (selectedTag === 'All') {
      this.setState({ filteredPages: this.state.pages, selectedTag: '' });
      return;
    }
    let filteredPages = this.state.pages.filter((page) =>
      page.metadata.tags.find((curTag) => selectedTag === toHumanReadable(curTag.sys.id))
    );
    this.setState({ filteredPages: filteredPages, selectedTag: selectedTag });
  };

  render() {
    const { headerLargeDesktopProps, footerProps } = this.props;

    return (
      <div>
        <Routes>
          {this.state.pages.map((entry, i) => {
            return (
              <Route
                path={entry.fields.slug}
                key={i}
                element={
                  <ResourcePage
                    headerLargeDesktopProps={headerLargeDesktopProps}
                    footerProps={footerProps}
                    data={entry}
                  />
                }
              />
            );
          })}
          <Route path='/' element={this.renderMainResourcesPage()} />
        </Routes>
      </div>
    );
  }

  renderMainResourcesPage() {
    const { place, anglesRight, spanText1, spanText2, headerLargeDesktopProps, footerProps } = this.props;

    return (
      <div className='container-center-horizontal'>
        <div className='lrg-resources screen'>
          <Outlet />
          <Helmet>
            <meta
              name='robots'
              content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
            />
            <title>Resources | Rivelin</title>
            <meta
              name='description'
              content='A place for white papers, case studies, press releases, trade show news and announcements'
            />
            <meta
              name='keywords'
              content='metal post processing, metal am post processing, metal surface finishing, metal support removal, metal polishing, metal powder removal, Rivelin NetShape'
            />
            <meta
              property='og:description'
              content='A place for white papers, case studies, press releases, trade show news and announcements'
            />
            <meta property='og:site_name' content='Rivelin Robotics' />
            <meta property='og:title' content='Resources' />
            <meta
              name='og:image'
              content='https://images.ctfassets.net/bjia12x471fb/32bBHHxQIHO1tyMCPU2Mut/dfc487843c06a9807bd659f9e0a5490c/CranialPlate_Scan.png'
            />
            <meta
              name='og:image:secure_url'
              content='https://images.ctfassets.net/bjia12x471fb/32bBHHxQIHO1tyMCPU2Mut/dfc487843c06a9807bd659f9e0a5490c/CranialPlate_Scan.png'
            />
            <meta
              name='image'
              property='og:image'
              content='https://images.ctfassets.net/bjia12x471fb/32bBHHxQIHO1tyMCPU2Mut/dfc487843c06a9807bd659f9e0a5490c/CranialPlate_Scan.png'
            />
            <meta property='og:image:type' content='image/png' />
          </Helmet>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <div className='nav-bar-19'>
            <HashLink to='/#top'>
              <div className='place-8 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right-7' src={anglesRight} alt={anglesRight} />
            <div className='who-we-are-placeholder-5 atkinsonhyperlegible-normal-onyx-22px-2'>
              <span className='span0-12 atkinsonhyperlegible-normal-onyx-22px'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText2}</span>
            </div>
          </div>
          {this.state.loading && (
            <div className='status-center'>
              <span className='atkinsonhyperlegible-bold-onyx-20px-2'>Loading...</span>
            </div>
          )}
          {this.state.error && (
            <div className='status-center'>
              <span className='atkinsonhyperlegible-bold-onyx-20px-2'>
                Something has gone wrong, please get in contact with our team.
              </span>
            </div>
          )}
          {!(this.state.loading && !this.state.error) && (
            <div className='grid-container-wrapper'>
              <div className='tags-container-wrapper atkinsonhyperlegible-normal-onyx-22px'>
                <div className='tags-container'>
                  <div
                    className={`tag ${this.state.selectedTag === '' ? 'selected' : ''}`}
                    onClick={this.handleTagSelection}
                  >
                    All
                  </div>
                  {this.state.tags.map((tagname, i) => (
                    <>
                      <div className='separator' key={`s${i}`}>
                        |
                      </div>
                      <div
                        className={`tag ${this.state.selectedTag === tagname ? 'selected' : ''}`}
                        onClick={this.handleTagSelection}
                        key={i}
                      >
                        {tagname}
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div className='hor-line' />
              <div className='grid-container'>
                {this.state.filteredPages.map((entry, i) => (
                  <div className='card-wrapper'>
                    <HashLink to={`/resources/${entry.fields.slug}#top`} key={i}>
                      <ResourceIndex data={entry} />
                    </HashLink>
                  </div>
                ))}
              </div>
            </div>
          )}
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}
export default LrgResources;

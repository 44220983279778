import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import Footer from '../Footer';
import './LrgLearnTheTech.scss';
import ApplyNow from '../ApplyNow';
import MblLearnTheTech from '../MblLearnTheTech';
import maskGroup5 from '../../static/img/mask-group-5-1@1x.png';
import maskGroup6 from '../../static/img/mask-group-6-1@1x.png';
import maskGroup7 from '../../static/img/mask-group-7-1@1x.png';

const nS11Data = {
  spanText1: (
    <React.Fragment>
      TWO
      <br />
    </React.Fragment>
  ),
  spanText2: 'It Adapts.',
};

const nS12Data = {
  spanText1: (
    <React.Fragment>
      THREE
      <br />
    </React.Fragment>
  ),
  spanText2: 'It Finishes.',
};

const googlePixel6ProLearnTheTechData = {
  title: 'Truly Autonomous Finishing',
  spanText1: (
    <React.Fragment>
      ONE
      <br />
    </React.Fragment>
  ),
  spanText2: 'It Sees.',
  rivelinSSystemSca: (
    <React.Fragment>
      Rivelin&#x27;s system scans and creates a 3D model of each part. <br />
      <br />
      Proprietary AI snaps the scanned 3D model to the net shape CAD model.
    </React.Fragment>
  ),
  rivelinSSystemRec: (
    <React.Fragment>
      Rivelin NetShape<span className='atkinsonhyperlegible-super'>®</span> software recognises variability
      and generates intelligent finishing paths to compensate. <br />
      <br />
      NetShape<span className='atkinsonhyperlegible-super'>®</span> adapts to imprecise parts, fixturing
      variation and slipping in the chuck
    </React.Fragment>
  ),
  theRivelinSystemU: (
    <React.Fragment>
      The Rivelin System understands finishing and continuously builds a map of surface topologies, high/low
      spots and tool wear.
      <br />
      <br />
      Rivelin uses standard finishing tools like belt sanders, micro grinders, ultrasonic polishers, nippers
      and pneumatic hammers - only manipulated by a robot.
    </React.Fragment>
  ),
  maskGroup7: '/img/mask-group-7-1x-png@1x.png',
  nS11Props: nS11Data,
  nS12Props: nS12Data,
};

class LLearnTheTech extends React.Component {
  render() {
    const {
      place,
      anglesRight,
      spanText1,
      spanText2,
      title,
      spanText3,
      spanText4,
      one,
      spanText5,
      spanText6,
      two,
      spanText7,
      spanText8,
      three,
      headerLDesktopAltProps,
      heroLargeDesktop2Props,
      footerProps,
    } = this.props;

    return (
      <div className='container-center-horizontal'>
        <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
          />
          <title>Learn The Tech | Rivelin</title>
          <meta
            name='description'
            content='Our superpower is NetShape and the team behind it. Proprietary AI enables NetShape Robots to adapt to variability'
          />
          <meta name='keywords' content='intelligent metal finishing, robot grinding, robot polishing' />
          <meta
            property='og:description'
            content='Our superpower is NetShape and the team behind it. Proprietary AI enables NetShape Robots to adapt to variability'
          />
          <meta property='og:site_name' content='Rivelin Robotics' />
          <meta property='og:title' content='Learn The Tech' />
          <meta name='og:image' content='https://www.rivelinrobotics.com/img/mask-group-6-1@1x.png' />
          <meta
            name='og:image:secure_url'
            content='https://www.rivelinrobotics.com/img/mask-group-6-1@1x.png'
          />
          <meta
            name='image'
            property='og:image'
            content='https://www.rivelinrobotics.com/img/mask-group-6-1@1x.png'
          />
          <meta property='og:image:type' content='image/png' />
        </Helmet>
        <div className='l-learn-the-tech screen'>
          <HeaderLargeDesktop {...headerLDesktopAltProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={heroLargeDesktop2Props.heroLargeDesktop2}
            title={heroLargeDesktop2Props.title}
            className={heroLargeDesktop2Props.className}
          />
          <div className='nav-bar-11'>
            <HashLink to='/#top'>
              <div className='place-12 atkinsonhyperlegible-normal-onyx-22px'>{place}</div>
            </HashLink>
            <img className='angles-right-1' src={anglesRight} alt={anglesRight} />
            <div className='who-we-are-placeholder-1 atkinsonhyperlegible-normal-onyx-22px-2'>
              <span className='span0-4 atkinsonhyperlegible-normal-onyx-22px'>{spanText1}</span>
              <span className='atkinsonhyperlegible-normal-onyx-22px'>{spanText2}</span>
            </div>
          </div>
          <MblLearnTheTech {...googlePixel6ProLearnTheTechData} />
          <div className='ltr-shrinker'>
            <div className='page-stack-1'>
              <div className='title-3 atkinsonhyperlegible-bold-bunker-40px'>{title}</div>
              <div className='one-it-sees'>
                <div className='overlap-group4-1'>
                  <img className='mask-group-5-1' src={maskGroup5} alt={maskGroup5} />
                  <div className='it-seesrivelins atkinsonhyperlegible-normal-onyx-50px'>
                    <span className='span0-3 atkinsonhyperlegible-bold-bunker-50px'>{spanText3}</span>
                    <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText4}</span>
                  </div>
                </div>
                <div className='one atkinsonhyperlegible-normal-bunker-26px'>{one}</div>
              </div>
              <div className='two-it-adapts'>
                <div className='overlap-group3-1'>
                  <div className='overlap-group2'>
                    <img className='mask-group-6-1' src={maskGroup6} alt={maskGroup6} />
                    <div className='it-adaptsrivelin atkinsonhyperlegible-normal-onyx-50px'>
                      <span className='span0-3 atkinsonhyperlegible-bold-bunker-50px'>{spanText5}</span>
                      <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText6}</span>
                    </div>
                  </div>
                  <div className='two atkinsonhyperlegible-normal-bunker-26px'>{two}</div>
                </div>
              </div>
              <div className='three-it-understands'>
                <div className='overlap-group1-8'>
                  <div className='overlap-group-14'>
                    <img className='mask-group-7-1' src={maskGroup7} alt={maskGroup7} />
                    <div className='it-finishesthe-ri atkinsonhyperlegible-normal-onyx-50px'>
                      <span className='span0-3 atkinsonhyperlegible-bold-bunker-50px'>{spanText7}</span>
                      <span className='atkinsonhyperlegible-normal-onyx-26px'>{spanText8}</span>
                      <ApplyNow
                        applyNow='Read About Our Solutions'
                        link='/why-we-exist#solutions'
                        className='solutions-link'
                        offset={-200}
                      />
                    </div>
                  </div>
                  <div className='three atkinsonhyperlegible-normal-bunker-26px'>{three}</div>
                </div>
              </div>
            </div>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default LLearnTheTech;

import React from 'react';
import Markdown from 'marked-react';
import HeaderLargeDesktop from '../HeaderLargeDesktop';
import HeroLargeDesktop2 from '../HeroLargeDesktop2';
import { HashLink } from 'react-router-hash-link';
import Footer from '../Footer';
import '../ResourcePage/ResourcePage.scss';
import { Helmet } from 'react-helmet';
import anglesrightImage from '../../static/img/angles-right-1@1x.png';

class ResourcePage extends React.Component {
  render() {
    const { data, headerLargeDesktopProps, footerProps } = this.props;

    return (
      <div className='container-center-horizontal'>
        <div className='resource-page screen'>
          <Helmet>
            <meta name='Algolia crawler' content='noindex' />
            <meta
              name='robots'
              content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
            />
            <title>{data.fields.title} | Rivelin</title>
            <meta name='description' content={data.fields.description} />
            <meta name='keywords' content={data.fields.keywords} />
            <meta property='og:description' content={data.fields.description} />
            <meta property='og:site_name' content='Rivelin Robotics' />
            <meta property='og:title' content={data.fields.title} />
            <meta name='og:image' content={data.fields.featuredImage.fields.file.url} />
            <meta name='og:image:secure_url' content={data.fields.featuredImage.fields.file.url} />
            <meta name='image' property='og:image' content={data.fields.featuredImage.fields.file.url} />
            <meta property='og:image:type' content='image/png' />
          </Helmet>
          <HeaderLargeDesktop {...headerLargeDesktopProps} />
          <HeroLargeDesktop2
            heroLargeDesktop2={data.fields.featuredImage.fields.file.url}
            title={''}
            className={''}
            // img={heroLargeDesktop2Props.img}
          />
          <div className='nav-bar-42'>
            <HashLink to='/#top'>
              <div className='place-1 atkinsonhyperlegible-normal-onyx-22px'>Home</div>
            </HashLink>
            <img className='angles-right' src={anglesrightImage} alt={anglesrightImage} />
            <HashLink to='/resources#top'>
              <div className='who-we-are-link atkinsonhyperlegible-normal-onyx-22px'>Resources</div>
            </HashLink>
            <img className='angles-right' src={anglesrightImage} alt={anglesrightImage} />
            <div className='careers-placeholder atkinsonhyperlegible-normal-onyx-22px'>
              {data.fields.title}
            </div>
          </div>
          <div className='article-body'>
            <div className='atkinsonhyperlegible-bold-bunker-46px'>{data.fields.title}</div>
            <div className='small'>{new Date(data.fields.date).toDateString()}</div>
            <Markdown>{data.fields.body}</Markdown>
          </div>
          <Footer
            className={footerProps.className}
            privacyPolicyLinkProps={footerProps.privacyPolicyLinkProps}
            youtubeProps={footerProps.youtubeProps}
            linkedinProps={footerProps.linkedinProps}
            githubProps={footerProps.githubProps}
          />
        </div>
      </div>
    );
  }
}

export default ResourcePage;

import React from 'react';
import './CopyrightNote.scss';

class CopyrightNote extends React.Component {
  render() {
    return (
      <div className='copyright-note'>
        <p className='copyright atkinsonhyperlegible-normal-white-16px'>
          © 2023 Rivelin Robotics&nbsp;&nbsp;|
        </p>
      </div>
    );
  }
}

export default CopyrightNote;
